import React from 'react';
import { useMemo, useRef } from 'hooks/hooks.js';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import clsx from 'clsx';
import { useStyles } from './Highcharts.style.js';
import {
  frequencyBySeconds,
  getUnixDateByStr,
  numberFormat,
} from 'utils/helpers/helpers.js';
import { getDatetimeFormat } from './libs/utils.js';
import { GRAPH_HEIGHT } from 'constants/constants.js';
import { HIGHCHARTS_SETTINGS } from './libs/constants.js';
import projectTheme from 'theme.js';

Highcharts.setOptions(HIGHCHARTS_SETTINGS);

const JobsExecution = ({
  data,
  frequencyMilliseconds,
  selectedDatetime = null,
  onChangeSelectedDate,
  graphHeight = GRAPH_HEIGHT.medium,
}) => {
  const classes = useStyles();
  const graphRef = useRef(null);

  const formattedData = useMemo(() => {
    return data.map(({ datetime, value }) => ({
      x: getUnixDateByStr(datetime),
      y: value,
    }));
  }, [data]);

  const selectValue = (event) => {
    const volumeGraph = graphRef.current.chart;

    const pointerEvent = volumeGraph.pointer.normalize(event);
    const point = volumeGraph.series[0].searchPoint(pointerEvent, true); // find the point

    if (!point) {
      return null;
    }

    if (getUnixDateByStr(selectedDatetime) === getUnixDateByStr(point.x)) {
      onChangeSelectedDate(null);
    } else {
      onChangeSelectedDate(point.x);
    }
  };

  const options = {
    chart: {
      zooming: { type: 'x' },
      events: {
        click: (event) => selectValue(event),
      },
      height: graphHeight,
    },
    title: { text: undefined },
    subtitle: {
      text: `aggregation step: ${frequencyBySeconds(
        frequencyMilliseconds * 0.001
      )}`,
      align: 'right',
      style: {
        color: projectTheme.palette.text.secondary,
        fontWeight: 700,
      },
    },
    xAxis: {
      type: 'datetime',
      labels: {
        format: '{value: %b %d, %I:%M %p}',
        step: 3,
      },
    },
    yAxis: [{ title: { text: undefined } }],
    tooltip: {
      crosshairs: true,
      shared: true,
      useHTML: true,
      formatter: function () {
        return `
          <div style="font-size: 0.8em">${getDatetimeFormat(this.x)}</div>
          <div>
            <span style="color: ${Highcharts.getOptions().colors[0]}">
              ●
            </span>
            jobs count: <b>${numberFormat(this.y)}</b>
          </div>
        `;
      },
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: true,
          radius: 3,
        },
        states: {
          hover: { enabled: false },
          inactive: { enabled: false },
        },
      },
    },
    series: [
      {
        type: 'line',
        data: formattedData,
      },
    ],
  };

  if (selectedDatetime !== null) {
    const x = getUnixDateByStr(selectedDatetime);
    const y = formattedData?.filter(
      (item) => item.x === getUnixDateByStr(selectedDatetime)
    )[0].y;

    options.series.push({
      type: 'scatter',
      data: [{ x, y }],
      marker: {
        symbol: 'circle',
        radius: 4,
        fillColor: Highcharts.getOptions().colors[0],
        lineWidth: 5,
        lineColor: 'rgba(47, 80, 255, 0.3)',
      },
    });
  }

  return (
    <div className={clsx(classes.container, 'zoom-effects')}>
      <HighchartsReact
        ref={graphRef}
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export { JobsExecution };
