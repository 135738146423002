import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',

    '& .resetButton': {
      position: 'absolute',
      top: 380,
      right: 0,
      zIndex: 10,
    },
    '&.zoom-effects': {
      cursor: 'crosshair',
    },
    '& .highcharts-credits': {
      display: 'none',
    },
    '& .highcharts-range-input': {
      pointerEvents: 'none',
    },
  },
}));
