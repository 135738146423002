import React from 'react';
import {
  useAutoChangeProject,
  useBqBillingData,
  useEffect,
  useGetProjects,
} from 'hooks/hooks.js';
import {
  PageHeader,
  Pagination,
  PositionLoader,
  Tabs,
} from 'Components/components.js';
import {
  CostsTab,
  CostsWelcome,
  PipelineTab,
  ResourceTab,
} from './components/components.js';
import {
  PipelinePageContextProvider,
  usePipelinePageContext,
  useUserInfo,
} from 'context/context.js';
import {
  DAYS_LIMIT,
  DOCUMENT_TITLE,
  PipelineTabs,
} from 'constants/constants.js';

const YALO_TENANT = 'yalo-n8gj5';

const PipelinesContainer = () => {
  return (
    <PipelinePageContextProvider>
      <Pipelines />
    </PipelinePageContextProvider>
  );
};

const Pipelines = () => {
  const {
    currentTab,
    updateParams,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationTotal,
  } = usePipelinePageContext();

  const { isWelcome, bqBillingData } = useBqBillingData();
  const { projects, isLoadingProjects } = useGetProjects();
  const { user, isCurrentProjectDemo } = useUserInfo();
  const isYaloTenant =
    user.info?.tenant === YALO_TENANT ||
    user.info?.adminData?.currentTenant === YALO_TENANT;

  useAutoChangeProject();

  useEffect(() => {
    document.title = DOCUMENT_TITLE.pipelines;
  }, []);

  const hasDateRangePicker = currentTab !== PipelineTabs.PIPELINE_INFO;
  const rangePickerDaysLimit = isYaloTenant
    ? DAYS_LIMIT.threeMonths
    : DAYS_LIMIT.month;

  const renderPage = () => {
    switch (currentTab) {
      case PipelineTabs.PIPELINE_INFO:
        return <PipelineTab />;
      case PipelineTabs.RESOURCE_ALLOCATION:
        return <ResourceTab />;
      default:
        return <CostsTab />;
    }
  };

  if (isLoadingProjects) {
    return <PositionLoader />;
  }

  if (isWelcome && !isCurrentProjectDemo) {
    return <CostsWelcome projects={projects} bqBillingData={bqBillingData} />;
  }

  return (
    <>
      <PageHeader
        hasDateRangePicker={hasDateRangePicker}
        daysLimit={rangePickerDaysLimit}
      >
        Pipelines
      </PageHeader>

      <Tabs tabs={PipelineTabs} onTabCLick={updateParams} />

      {renderPage()}

      <Pagination
        count={paginationTotal}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </>
  );
};

export default PipelinesContainer;
