import React from 'react';
import {
  usePagination,
  useState,
  useEffect,
  useCallback,
  useAutoChangeProject,
} from 'hooks/hooks.js';
import { useStyles } from './Incidents.styles.js';
import { BQTab, LookerTab, PipelineTab } from './components/components.js';
import {
  Pagination,
  Tabs,
  PageHeader,
  Backdrop,
} from 'Components/components.js';
import { useQueryParamsContext } from 'context/context.js';
import { numberFormat } from 'utils/helpers/helpers.js';
import {
  SEARCH_PARAMS,
  IncidentTabs,
  DOCUMENT_TITLE,
} from 'constants/constants.js';

const PARAMS_TO_REMOVE = [
  SEARCH_PARAMS.TABLE,
  SEARCH_PARAMS.DATASET,
  SEARCH_PARAMS.SEARCH,
  SEARCH_PARAMS.ID,
  SEARCH_PARAMS.TYPE,
];

const Incidents = () => {
  const classes = useStyles();
  const { queryParams } = useQueryParamsContext();

  const [existingDatePicker, setExistingDatePicker] = useState(true);

  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();

  useAutoChangeProject();

  const currentTab = queryParams.get(SEARCH_PARAMS.TAB);
  const [totalIncidents, setTotalIncidents] = useState(0);
  const [titleTotalIncidents, setTitleTotalIncidents] = useState(0);
  const [isOpenBackdrop, setIsOpenBackdrop] = useState(false);

  useEffect(() => {
    document.title = DOCUMENT_TITLE.incidents;
  }, []);

  const handleChangeTotalIncidents = useCallback((value) => {
    setTotalIncidents(value);
  }, []);

  const handleChangeTitleTotalIncidents = useCallback((value) => {
    setTitleTotalIncidents(value);
  }, []);

  const handleChangeOpenBackdrop = useCallback((value) => {
    setIsOpenBackdrop(value);
  }, []);

  const handleChangeDateRangePicker = useCallback((value) => {
    setExistingDatePicker(value);
  }, []);

  const renderPage = () => {
    switch (currentTab) {
      case IncidentTabs.LOOKER:
        return (
          <LookerTab
            handleChangeTotalIncidents={handleChangeTotalIncidents}
            handleChangeTitleTotalIncidents={handleChangeTitleTotalIncidents}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onChangeOpenBackdrop={handleChangeOpenBackdrop}
            onChangeExistingDatePicker={handleChangeDateRangePicker}
          />
        );
      case IncidentTabs.PIPELINE:
        return (
          <PipelineTab
            handleChangeTotalIncidents={handleChangeTotalIncidents}
            handleChangeTitleTotalIncidents={handleChangeTitleTotalIncidents}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onChangeOpenBackdrop={handleChangeOpenBackdrop}
            onChangeExistingDatePicker={handleChangeDateRangePicker}
          />
        );
      default:
        return (
          <BQTab
            handleChangeTotalIncidents={handleChangeTotalIncidents}
            handleChangeTitleTotalIncidents={handleChangeTitleTotalIncidents}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onChangeOpenBackdrop={handleChangeOpenBackdrop}
            onChangeExistingDatePicker={handleChangeDateRangePicker}
          />
        );
    }
  };

  return (
    <>
      <PageHeader hasDateRangePicker={existingDatePicker}>
        Incidents ({numberFormat(titleTotalIncidents)})
      </PageHeader>

      <div className={classes.tableContainer}>
        <Tabs tabs={IncidentTabs} paramsToRemove={PARAMS_TO_REMOVE} />

        {renderPage()}

        <Backdrop isOpen={isOpenBackdrop} isPageCentering={true} />
      </div>

      {!isOpenBackdrop && (
        <Pagination
          count={totalIncidents}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default Incidents;
