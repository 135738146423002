import React from 'react';
import { useMemo } from 'hooks/hooks.js';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { useStyles } from './StatsWidget.styles.js';
import {
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
  PercentStatsBlock,
} from 'Components/components.js';
import { useQueryParamsContext } from 'context/context.js';
import {
  CostsStatsGraphView,
  CostsStatsTableView,
} from './components/components.js';
import { getTotalPipelineDiff, numberFormat } from 'utils/helpers/helpers.js';
import { COSTS_STATS_VIEW_TYPE, SEARCH_PARAMS } from 'constants/constants.js';

const CostsStatsWidget = ({
  costsStats,
  costsStatsPrevious,
  className = '',
  viewType = COSTS_STATS_VIEW_TYPE.table,
}) => {
  const classes = useStyles();

  return (
    <section className={clsx(classes.widget, className)}>
      <HeaderBlock
        costsStats={costsStats}
        costsStatsPrevious={costsStatsPrevious}
        withLink={viewType === COSTS_STATS_VIEW_TYPE.table}
      />

      {viewType === COSTS_STATS_VIEW_TYPE.graph ? (
        <CostsStatsGraphView />
      ) : (
        <CostsStatsTableView
          costsStats={costsStats}
          costsStatsPrevious={costsStatsPrevious}
        />
      )}
    </section>
  );
};

const HeaderBlock = ({
  costsStats = [],
  costsStatsPrevious = [],
  withLink = false,
}) => {
  const classes = useStyles();
  const { updateQueryParams } = useQueryParamsContext();

  const totalAmount = useMemo(() => {
    return costsStats.reduce((acc, { costs }) => acc + costs, 0);
  }, [costsStats]);

  const totalAmountPrevious = useMemo(() => {
    return costsStatsPrevious.reduce((acc, { costs }) => acc + costs, 0);
  }, [costsStatsPrevious]);

  const totalAmountInfo = getTotalPipelineDiff(
    totalAmount,
    totalAmountPrevious
  );

  const handleClickLink = (event) => {
    event.preventDefault();
    updateQueryParams({
      [SEARCH_PARAMS.PIPELINE_SUBTYPES]: null,
      [SEARCH_PARAMS.PIPELINE_TYPE]: '',
    });
  };

  return (
    <section>
      <Typography color={TYPOGRAPHY_COLOR.textSecondary} className='mb-2'>
        Pipeline compute costs
      </Typography>

      <section className={classes.flexRawContainer}>
        {withLink ? (
          <Link to={'#'} onClick={(event) => handleClickLink(event)}>
            <Typography variant={TYPOGRAPHY_VARIANT.h2} component={'h3'}>
              ${numberFormat(totalAmount)}
            </Typography>
          </Link>
        ) : (
          <Typography variant={TYPOGRAPHY_VARIANT.h2} component={'h3'}>
            ${numberFormat(totalAmount)}
          </Typography>
        )}

        {totalAmountInfo !== null && (
          <PercentStatsBlock data={totalAmountInfo} />
        )}
      </section>
    </section>
  );
};

export { CostsStatsWidget };
