import { shallowEqual } from 'react-redux';
import {
  useDispatch,
  useEffect,
  useOnClickOutside,
  useRef,
  useSelector,
  useState,
  useLocation,
} from 'hooks/hooks.js';
import clsx from 'clsx';
import Tree from 'rc-tree';
import 'rc-tree/assets/index.css';
import { ResizableBox } from 'react-resizable';
import { useStyles } from './ProjectTree.styles.js';
import { useProjectTree, useUserInfo } from 'context/context.js';
import { TABLE_TYPES } from 'constants/constants.js';
import {
  getProjectTreeDatasets,
  getProjectTreeTables,
  selectProjectTreeTable,
} from 'slices/actions.js';
import {
  getFolderMatch,
  getTableKey,
  getTableFolderKey,
  getTableTypeKey,
  getTableInfoFromNode,
} from 'slices/project-tree/utils.js';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as CollapseIcon } from 'assets/img/icons/collapseIcon.svg';
import { ReactComponent as ExpandIcon } from 'assets/img/icons/expandIcon.svg';
import { ReactComponent as ExternalIcon } from 'assets/img/icons/projectTreeIcons/external20.svg';
import { ReactComponent as PartitionIcon } from 'assets/img/icons/projectTreeIcons/partition.svg';
import { ReactComponent as ViewIcon } from 'assets/img/icons/projectTreeIcons/view20.svg';
import { ReactComponent as MaterializedViewIcon } from 'assets/img/icons/projectTreeIcons/materialized-view20.svg';
import { ReactComponent as TableIcon } from 'assets/img/icons/projectTreeIcons/table20.svg';
import { ReactComponent as DatasetIcon } from 'assets/img/icons/projectTreeIcons/dataset.svg';
import { ReactComponent as FolderIcon } from 'assets/img/icons/projectTreeIcons/folder.svg';

const TABLES_PER_PAGE = 1000;
const TREE_ITEM_HEIGHT = 22;
const TREE_ITEM_FULL_HEIGHT = 34;
const TREE_SCROLL_TO_TABLE_OFFSET = TREE_ITEM_FULL_HEIGHT * 5;
const TREE_TITLE_HEIGHT = 60;
const TREE_TOP_PADDING = 16;
const TREE_SCROLL_TIMEOUT = 50;
const TREE_BASE_WIDTH = 320;

const treeItemIcon = (obj) => {
  if (obj.isLeaf) {
    if (obj.isPartition) {
      return <PartitionIcon />;
    }

    const iconsByType = {
      [TABLE_TYPES.view]: <ViewIcon />,
      [TABLE_TYPES.materializedView]: <MaterializedViewIcon />,
      [TABLE_TYPES.external]: <ExternalIcon />,
    };

    return iconsByType[obj.type] || <TableIcon />;
  } else if (obj.isDataset) {
    return <DatasetIcon />;
  } else if (obj.page) {
    if (obj.loading) {
      return <CircularProgress size={14} />;
    }
    if (!obj.isLoadMore) {
      return <FolderIcon />;
    }
  }

  return null;
};

const ProjectTree = ({ left }) => {
  const ref = useRef();
  const treeRef = useRef();
  const classes = useStyles({
    left,
    itemHeight: TREE_ITEM_HEIGHT,
    itemFullHeight: TREE_ITEM_FULL_HEIGHT,
  });
  const dispatch = useDispatch();
  const location = useLocation();

  const treeMaxWidth =
    window.innerWidth / 2 > TREE_BASE_WIDTH
      ? window.innerWidth / 2
      : TREE_BASE_WIDTH;
  const [treeWidth, setTreeWidth] = useState(TREE_BASE_WIDTH);
  const [treeHeight, setTreeHeight] = useState(window.innerHeight);

  const [treeWasOpened, setTreeWasOpened] = useState(false);
  const [treeIsLoaded, setTreeIsLoaded] = useState(false);
  const [treeExpandedKeys, setTreeExpandedKeys] = useState([]);
  const [treeLoadedKeys, setTreeLoadedKeys] = useState([]);
  const [treeSelectedKeys, setTreeSelectedKeys] = useState([]);
  const [treeSelectedTable, setTreeSelectedTable] = useState(null);
  const [treeScrollToTable, setTreeScrollToTable] = useState(false);
  const [treeWasSelected, setTreeWasSelected] = useState(false);

  const { isOpen, selectTable, onSelectTable } = useProjectTree();
  const { currentProject } = useUserInfo();

  const resetTreeWidth = () => {
    setTreeWidth(TREE_BASE_WIDTH);
  };

  const getTreeDatasetKey = (tableItem) => {
    return tableItem ? tableItem.dataset : null;
  };
  const getTreeTableKey = (tableItem) => {
    return tableItem
      ? getTableKey(tableItem.dataset, tableItem.type, tableItem.table)
      : null;
  };
  const getTreeTableTypeKey = (tableItem) => {
    return tableItem
      ? getTableTypeKey(tableItem.dataset, tableItem.type)
      : null;
  };
  const getTreeTableFolderKey = (tableItem) => {
    const folderMatch = getFolderMatch(tableItem?.table);
    return folderMatch
      ? getTableFolderKey(tableItem.dataset, tableItem.type, folderMatch)
      : null;
  };

  const setTreeExpandedKeysByTable = (tableItem, initialKeys = []) => {
    const tmpExpandedKeys = [
      ...initialKeys,
      getTreeDatasetKey(tableItem),
      getTreeTableTypeKey(tableItem),
    ];
    const tableFolderKey = getTreeTableFolderKey(tableItem);
    if (tableFolderKey) {
      tmpExpandedKeys.push(tableFolderKey);
    }
    setTreeExpandedKeys([...new Set(tmpExpandedKeys)]);
  };

  const scrollToTop = () => {
    if (treeRef.current === undefined) {
      return;
    }
    treeRef.current.scrollTo({
      index: 0,
      align: 'top',
    });
  };

  const scrollToDataset = (tableItem) => {
    if (!isOpen) {
      return;
    }
    setTimeout(() => {
      if (treeRef.current === undefined) {
        return;
      }
      const currentItem = tableItem ?? treeSelectedTable;
      const tableKey = getTreeTableKey(currentItem);
      if (treeRef.current.state.keyEntities.hasOwnProperty(tableKey)) {
        scrollToTable(currentItem, true, 0);
      } else {
        const key = getTreeDatasetKey(currentItem);
        treeRef.current.scrollTo({
          key: key,
          align: 'top',
        });
      }
    }, TREE_SCROLL_TIMEOUT);
  };

  const scrollToTable = (
    tableItem,
    force = false,
    timeout = TREE_SCROLL_TIMEOUT
  ) => {
    if (!isOpen) {
      return;
    }
    setTimeout(() => {
      if (treeRef.current === undefined || (!treeScrollToTable && !force)) {
        return;
      }
      const currentItem = tableItem ?? treeSelectedTable;
      setTreeExpandedKeysByTable(currentItem);
      const key = getTreeTableKey(currentItem);
      treeRef.current.scrollTo({
        key: key,
        align: 'top',
        offset: TREE_SCROLL_TO_TABLE_OFFSET,
      });
      setTreeScrollToTable(false);
    }, timeout);
  };

  const { treeData } = useSelector(
    (state) => ({
      treeData: state.projectTree.treeData,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getProjectTreeDatasets());
  }, []);

  useEffect(() => {
    if (!treeLoadedKeys.length && treeData.length) {
      setTreeLoadedKeys(treeData.map((datasetItem) => datasetItem.key));
      setTreeIsLoaded(true);
    }
    scrollToTable();
  }, [treeData]);

  useEffect(() => {
    if (!treeIsLoaded) {
      return;
    }
    if (!location.search.includes('table=') && !treeWasSelected) {
      setTreeExpandedKeys([]);
      setTreeSelectedTable(null);
      scrollToTop();
    }
    setTreeWasSelected(false);
  }, [location]);

  const onLoadData = (treeNode) => {
    if (treeNode.dataset && treeNode.type && treeNode.page) {
      return dispatch(
        getProjectTreeTables({
          dataset: treeNode.dataset,
          tableType: treeNode.type,
          page: treeNode.page,
          limit: TABLES_PER_PAGE,
          selectTable: selectTable,
        })
      );
    }
    return new Promise((resolve) => {
      resolve();
    });
  };

  const onLoad = (loadedKeys) => {
    setTreeLoadedKeys(loadedKeys);
  };

  const onExpand = (expandedKeys) => {
    setTreeExpandedKeys(expandedKeys);
  };

  const onSelect = (_, { selected, node }) => {
    if (node?.isLoadMore) {
      return;
    }
    if (node?.isLeaf) {
      resetTreeWidth();
      setTreeWasSelected(true);
      setTreeSelectedTable(selected ? node : null);
      onSelectTable.onChange(
        selected ? getTableInfoFromNode(node, currentProject) : null
      );
    } else {
      setTreeSelectedKeys([node.key]);
    }
  };

  useEffect(() => {
    if (treeSelectedTable) {
      setTreeExpandedKeysByTable(treeSelectedTable, treeExpandedKeys);
      setTreeSelectedKeys([getTreeTableKey(treeSelectedTable)]);
    } else {
      setTreeSelectedKeys([]);
    }
  }, [treeSelectedTable]);

  useEffect(() => {
    if (treeSelectedTable?.uuid === selectTable?.uuid) {
      return;
    }

    setTreeSelectedTable(selectTable);

    if (selectTable) {
      setTreeScrollToTable(true);
      const tableTypeKey = getTreeTableTypeKey(selectTable);
      if (treeLoadedKeys.includes(tableTypeKey)) {
        dispatch(selectProjectTreeTable({ selectTable }));
      } else {
        scrollToDataset(selectTable);
      }
    }
  }, [selectTable]);

  useEffect(() => {
    if (isOpen) {
      setTreeWasOpened(true);
      if (treeSelectedTable) {
        setTreeExpandedKeysByTable(treeSelectedTable);
        setTreeSelectedKeys([getTreeTableKey(treeSelectedTable)]);
        scrollToDataset(treeSelectedTable);
      }
    }
  }, [isOpen]);

  const onTreeResize = (e, data) => {
    setTreeWidth(data.size.width);
  };

  const onWindowResize = () => {
    setTreeHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  });

  useOnClickOutside(ref, () => {
    if (treeRef.current) {
      resetTreeWidth();
    }
  });

  const treeItemSwitcherIcon = (obj) => {
    if (obj.isLeaf) {
      return;
    }
    return obj.expanded ? <CollapseIcon /> : <ExpandIcon />;
  };

  if (!treeWasOpened) {
    return '';
  }

  return (
    <>
      <div ref={ref} style={{ display: isOpen ? 'block' : 'none' }}>
        <div className={classes.treeDivider}></div>
        <ResizableBox
          className={classes.treeContainer}
          onResize={onTreeResize}
          height={treeHeight}
          width={treeWidth}
          minConstraints={[TREE_BASE_WIDTH, 0]}
          maxConstraints={[treeMaxWidth, Infinity]}
          axis={'x'}
          handle={
            <div className={classes.resizer}>
              <div className={classes.resizerDivider}></div>
            </div>
          }
        >
          <>
            <div className={clsx(classes.treeTitle, 'txt-mainDark-20-700')}>
              Data Sources
            </div>
            {treeIsLoaded && (
              <Tree
                ref={treeRef}
                treeData={treeData}
                loadData={onLoadData}
                checkable={false}
                selectable={true}
                expandAction={'click'}
                virtual={true}
                height={treeHeight - TREE_TITLE_HEIGHT - TREE_TOP_PADDING}
                itemHeight={TREE_ITEM_FULL_HEIGHT}
                onLoad={onLoad}
                onExpand={onExpand}
                onSelect={onSelect}
                loadedKeys={treeLoadedKeys}
                expandedKeys={treeExpandedKeys}
                selectedKeys={treeSelectedKeys}
                icon={treeItemIcon}
                switcherIcon={treeItemSwitcherIcon}
              />
            )}
          </>
        </ResizableBox>
      </div>
    </>
  );
};

export { ProjectTree };
