import React, { useEffect } from 'react';
import { useMemo, useAltPlanData, useBqBillingData } from 'hooks/hooks.js';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableRow, TableCell } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { useStyles } from './StatsWidget.styles';
import {
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
} from 'Components/components.js';
import { useDateContext } from 'context/context.js';
import { numberFormat } from 'utils/helpers/helpers.js';
import { amplEvent } from 'service/services.js';
import { AppRoutes } from 'app-routes.js';
import {
  SEARCH_PARAMS,
  ALTERNATIVE_PLAN_OPTION,
  DEAD_END_TABLES,
} from 'constants/constants.js';
import { BQ_BILLING_TYPE } from 'utils/constants.js';
import { ReactComponent as ArrowRight } from 'assets/img/icons/arrows/arrow-right-16.svg';

const useWidgetStyles = makeStyles((theme) => ({
  container: {
    '& .MuiTableRow-root': {
      '&:last-child .MuiTableCell-root': {
        paddingBottom: 0,
      },
    },
    '& .MuiTableCell-root': {
      padding: 0,
      paddingBottom: theme.spacing(4),
      borderBottom: 0,
    },
  },
  description: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  line: {
    height: theme.spacing(2),
    alignSelf: 'stretch',
    borderRadius: '100px',
  },
  savingsValues: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

const SavingsOptionsWidget = ({
  totalAmount,
  deadEndTablesStats,
  onChangeSavingsOptionsWidget,
  className = '',
}) => {
  const classes = useStyles();
  const widgetStyles = useWidgetStyles();
  const { currentBqBillingData } = useBqBillingData();
  const { paymentType } = currentBqBillingData || {};
  const { altPlanData } = useAltPlanData();

  const isStatsExists = useMemo(() => {
    return {
      altPlan: Boolean(altPlanData?.total && paymentType),
      altBillingPlan: Boolean(
        altPlanData?.altPlanCosts < totalAmount && paymentType
      ),
      deadEndTables: Boolean(deadEndTablesStats?.total),
    };
  }, [altPlanData, deadEndTablesStats, paymentType, totalAmount]);

  const alternativePlan = useMemo(() => {
    if (paymentType) {
      return paymentType === BQ_BILLING_TYPE.ON_DEMANDS
        ? 'Editions Standard'
        : 'On-Demand';
    }

    return null;
  }, [paymentType]);

  const altPlanWidgetData = useMemo(() => {
    const totalCosts = altPlanData?.costs || 0;
    const savingProjectCosts = totalAmount - totalCosts;
    const percentageOfTotal = totalAmount
      ? ((totalCosts / totalAmount) * 100).toFixed(2)
      : 0;

    return {
      name: ALTERNATIVE_PLAN_OPTION,
      description: 'Alternative plan.',
      savingPercentage: percentageOfTotal,
      savingCosts: `$${numberFormat(totalCosts)}`,
      projectCosts: `$${numberFormat(savingProjectCosts)}`,
      lineColor: '#DECDEF',
      linkText:
        altPlanData?.total === 1
          ? 'See pipeline'
          : `See all ${numberFormat(altPlanData?.total)} pipelines`,
    };
  }, [altPlanData, totalAmount]);

  const deadEndWidgetData = useMemo(() => {
    const deadEndCosts = deadEndTablesStats?.costs || 0;
    const savingProjectCosts = totalAmount - deadEndCosts;
    const percentageOfTotal = totalAmount
      ? ((deadEndCosts / totalAmount) * 100).toFixed(2)
      : 0;

    return {
      name: DEAD_END_TABLES,
      description: 'Clean dead-end tables.',
      savingPercentage: percentageOfTotal,
      savingCosts: `$${numberFormat(deadEndCosts)}`,
      projectCosts: `$${numberFormat(savingProjectCosts)}`,
      lineColor: '#FEE8E8',
      linkText:
        deadEndTablesStats?.total === 1
          ? 'See table'
          : `See all ${numberFormat(deadEndTablesStats?.total)} tables`,
    };
  }, [deadEndTablesStats, totalAmount]);

  const altBillingData = useMemo(() => {
    const totalCosts = altPlanData?.altPlanCosts || 0;
    const savingCosts = totalAmount - totalCosts;
    const percentageOfTotal = totalAmount
      ? Number(((savingCosts / totalAmount) * 100).toFixed(2))
      : 0;

    return {
      name: 'ALT_BILLING',
      description: `Change project compute billing to ${alternativePlan}`,
      savingPercentage: percentageOfTotal > 0 ? percentageOfTotal : null,
      savingCosts: savingCosts > 0 ? `$${numberFormat(savingCosts)}` : '$0',
      projectCosts: `$${numberFormat(totalCosts)}`,
      lineColor: '#EBF2FF',
    };
  }, [altPlanData?.altPlanCosts, alternativePlan, totalAmount]);

  const widgetData = [
    ...(isStatsExists.altPlan ? [altPlanWidgetData] : []),
    ...(isStatsExists.altBillingPlan ? [altBillingData] : []),
    ...(isStatsExists.deadEndTables ? [deadEndWidgetData] : []),
  ];

  useEffect(() => {
    onChangeSavingsOptionsWidget(Boolean(widgetData.length));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgetData.length]);

  const handleLinkClick = (name) => {
    amplEvent(`Costs stats widget -> click -> ${name}`);
  };

  if (!widgetData.length) {
    return null;
  }

  return (
    <section className={clsx(classes.widget, className)}>
      <Typography color={TYPOGRAPHY_COLOR.textSecondary} className='mb-4'>
        Savings options
      </Typography>

      <Table className={widgetStyles.container}>
        <TableBody>
          {widgetData.map((item) => (
            <WidgetRow
              key={item.name}
              rowData={item}
              onLinkClick={handleLinkClick}
            />
          ))}
        </TableBody>
      </Table>
    </section>
  );
};

const WidgetRow = ({ rowData, onLinkClick }) => {
  const { daysLabel } = useDateContext();
  const classes = useStyles();
  const widgetStyles = useWidgetStyles();

  return (
    <TableRow>
      <TableCell>
        <div className={widgetStyles.description}>
          <span className='txt-grey-13-500'>
            {rowData.description}&nbsp;
            {rowData.linkText && (
              <Link
                to={{
                  pathname: AppRoutes.Pipelines.path,
                  search: new URLSearchParams({
                    [SEARCH_PARAMS.COST_EFFICIENCY]: rowData.name,
                  }).toString(),
                }}
                onClick={() => onLinkClick(rowData.description)}
              >
                <Typography color={TYPOGRAPHY_COLOR.primary} component='span'>
                  {rowData.linkText}
                </Typography>
                <ArrowRight className={classes.linkIcon} />
              </Link>
            )}
          </span>

          {rowData.savingPercentage !== null && (
            <div className='txt-mainDark-13-700'>
              {rowData.savingPercentage}% savings
            </div>
          )}
        </div>

        <div
          className={widgetStyles.line}
          style={{ backgroundColor: rowData.lineColor }}
        />
      </TableCell>

      <TableCell>
        <div className={widgetStyles.savingsValues}>
          <Typography variant={TYPOGRAPHY_VARIANT.h3}>
            {rowData.savingCosts}
          </Typography>
          <Typography color={TYPOGRAPHY_COLOR.textSecondary}>
            {rowData.projectCosts}/{daysLabel}
          </Typography>
        </div>
      </TableCell>
    </TableRow>
  );
};

export { SavingsOptionsWidget };
