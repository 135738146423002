const TABLE_TYPES = {
  dbTable: 'DB_TABLE',
  bqTable: 'BQ_TABLE',
  external: 'EXTERNAL',
  view: 'VIEW',
  materializedView: 'MATERIALIZED_VIEW',
  sharded: 'SHARDED',
  partition: 'PARTITION',
  stream: 'STREAM',
};

const VIEW_TABLE_TYPES = [TABLE_TYPES.view, TABLE_TYPES.materializedView];

export { TABLE_TYPES, VIEW_TABLE_TYPES };
