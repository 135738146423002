import React from 'react';
import {
  useCostsDailyStats,
  useCostsDailyStatsPrevious,
  useMemo,
  useRef,
} from 'hooks/hooks.js';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useStyles } from './Highcharts.style.js';
import { PositionLoader } from 'Components/components.js';
import { getUnixDateByStr, numberFormat } from 'utils/helpers/helpers.js';
import { GRAPH_HEIGHT } from 'constants/constants.js';
import { HIGHCHARTS_SETTINGS } from './libs/constants.js';
import projectTheme from 'theme.js';

const TIMEZONE_SEPARATOR = 'T';

const getDatetimeFormat = (datetime) => {
  return moment(datetime).utc().format('MMM DD');
};

Highcharts.setOptions(HIGHCHARTS_SETTINGS);

const CostsStatsGraph = () => {
  const classes = useStyles();
  const graphRef = useRef(null);

  const { isFetchingCostsDailyStats, costsDailyStats } = useCostsDailyStats();
  const { isFetchingCostsDailyStatsPrevious, costsDailyStatsPrevious } =
    useCostsDailyStatsPrevious();

  const formattedCostsDailyStats = useMemo(() => {
    return costsDailyStats.map(({ datetime, costs }) => ({
      x: getUnixDateByStr(datetime),
      y: costs,
    }));
  }, [costsDailyStats]);

  const formattedCostsDailyStatsPrevious = useMemo(() => {
    return costsDailyStatsPrevious.map(({ datetime, costs }) => ({
      x: getUnixDateByStr(datetime),
      y: costs,
    }));
  }, [costsDailyStatsPrevious]);

  const costsStatsContainsToday = useMemo(() => {
    const today = new Date().toISOString().split(TIMEZONE_SEPARATOR)[0];

    return costsDailyStats.some((item) => item.datetime.startsWith(today));
  }, [costsDailyStats]);

  const isLoading = useMemo(() => {
    return isFetchingCostsDailyStats || isFetchingCostsDailyStatsPrevious;
  }, [isFetchingCostsDailyStats, isFetchingCostsDailyStatsPrevious]);

  const options = {
    chart: {
      type: 'line',
      height: GRAPH_HEIGHT.medium,
      marginTop: 24,
    },
    title: { text: undefined },
    xAxis: [
      {
        type: 'datetime',
        labels: {
          format: '{value: %b %d, %I:%M %p}',
          step: 2,
        },
      },
      {
        visible: false,
        type: 'datetime',
        opposite: true,
      },
    ],
    yAxis: [{ title: { text: undefined } }],
    tooltip: {
      crosshairs: true,
      shared: true,
      formatter: function () {
        const currentPoint = this.point;
        const currentSeries = this.series;
        const otherSeries = this.series.chart.series.find(
          (item) => item.name !== currentSeries.name
        );

        let closestPoint = null;

        if (otherSeries) {
          closestPoint = otherSeries.points.find(
            (item) => item.plotX === currentPoint.plotX
          );
        }

        let tooltipHtml = `<span style="color:${
          currentSeries.color
        }">●</span> ${getDatetimeFormat(currentPoint.x)}: $${numberFormat(
          currentPoint.y
        )}<br/>`;

        if (closestPoint && closestPoint.y !== null) {
          tooltipHtml += `<span style="color:${
            otherSeries.color
          }">●</span> ${getDatetimeFormat(closestPoint.x)}: $${numberFormat(
            closestPoint.y
          )}<br/>`;
        }

        return tooltipHtml;
      },
    },
    legend: {
      align: 'left',
      margin: 4,
      events: { itemClick: () => false },
      itemStyle: {
        color: projectTheme.palette.text.secondary,
        fontSize: 13,
      },
      itemHoverStyle: {
        color: projectTheme.palette.text.secondary,
        cursor: 'default',
      },
      itemMarginBottom: 0,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: formattedCostsDailyStats?.length === 1 || false,
        },
        states: {
          hover: { enabled: false },
          inactive: { enabled: false },
        },
      },
    },
    series: [
      {
        data: formattedCostsDailyStats,
        name: 'Current period',
        color: projectTheme.palette.primary.main,
        zoneAxis: 'x',
        zones: [
          {
            value: costsStatsContainsToday
              ? formattedCostsDailyStats.at(-2)?.x
              : null,
          },
          {
            dashStyle: 'Dash',
          },
        ],
      },
      {
        data: formattedCostsDailyStatsPrevious,
        name: 'Previous period',
        xAxis: 1,
        color: projectTheme.palette.divider,
        dashStyle: 'Dash',
      },
    ],
  };

  return (
    <div className={classes.container} style={{ height: GRAPH_HEIGHT.medium }}>
      {isLoading ? (
        <PositionLoader />
      ) : (
        <HighchartsReact
          ref={graphRef}
          highcharts={Highcharts}
          options={options}
        />
      )}
    </div>
  );
};

export { CostsStatsGraph };
