import { useQuery, useMemo, useBqBillingData } from 'hooks/hooks.js';
import { fetcherGet } from 'utils/utils.js';
import { BQ_BILLING_TYPE } from 'utils/constants.js';
import { JOB_METRIC_TYPES, QUERY_TYPES } from 'constants/constants.js';

const MILLISECONDS_IN_SECONDS = 1000;

const useJobFrequency = (jobHash, enabled = true) => {
  const { currentBqBillingData } = useBqBillingData();
  const { paymentType } = currentBqBillingData || {};

  const metricType = useMemo(() => {
    if (paymentType) {
      return paymentType === BQ_BILLING_TYPE.ON_DEMANDS
        ? JOB_METRIC_TYPES.bytes
        : JOB_METRIC_TYPES.slots;
    }

    return null;
  }, [paymentType]);

  const {
    data,
    isFetching: isLoadingFrequency,
    refetch: refetchFrequency,
  } = useQuery(
    [QUERY_TYPES.jobFrequency, jobHash, metricType],
    ({ queryKey }) => {
      const [url, jobHash, metricType] = queryKey;
      return fetcherGet(url, {
        jobHash,
        metricType,
      });
    },
    { enabled: Boolean(jobHash && metricType && enabled) }
  );

  const frequency = useMemo(() => {
    return data?.value || null;
  }, [data?.value]);

  const frequencyMilliseconds = useMemo(() => {
    return frequency ? frequency * MILLISECONDS_IN_SECONDS : null;
  }, [frequency]);

  return {
    frequencyMilliseconds,
    frequency,
    isLoadingFrequency,
    refetchFrequency,
  };
};

export { useJobFrequency };
