import React from 'react';
import {
  useState,
  useEffect,
  useMemo,
  useBqBillingData,
  useCallback,
} from 'hooks/hooks.js';
import { StyledDropdown } from 'Components/components.js';
import { useQueryParamsContext } from 'context/context.js';
import { getKeyForURL } from 'Pages/Incidents/libs/helpers/helpers.js';
import {
  getObjectKeyByValue,
  getPipelineMetaData,
  getDropdownValueByURL,
  isDropdownsEqual,
} from 'utils/helpers/helpers.js';
import {
  INCIDENT_FILTER_OPTIONS,
  SHORT_INCIDENT_FILTER,
  STATUS_FILTER_OPTIONS,
  LOOKER_STATUS_FILTER_OPTIONS,
  SEARCH_PARAMS,
  SEARCH_PARAMS_SEPARATOR,
  PIPELINES_BILLED_BYTES_FILTER_OPTIONS,
  PIPELINES_BILLED_SLOTS_FILTER_OPTIONS,
} from 'constants/constants.js';
import {
  ALERT_FILTER_OPTIONS,
  BQ_BILLING_TYPE,
  PIPELINE_TYPE,
} from 'utils/constants.js';
import {
  INCIDENT_STATUS_FILTER,
  INCIDENT_TYPE_FILTER,
  LOOKER_ALERT_FILTER,
  INCIDENT_STATUS_SHORT_FILTER,
  TABLE_ALERT_FILTER,
  PIPELINE_INCIDENT_TYPE_FILTER,
  TECHNOLOGY_FILTER,
  LOOKER_INCIDENT_TYPE_FILTER,
  INITIAL_TECHNOLOGY_OPTION,
} from 'Pages/Incidents/libs/constants/constants.js';
import { RegularMiniIcon } from 'assets/img/alert';
import { StatusIcon } from 'assets/img/dropdownIcons';
import { ReactComponent as TechnologyIcon } from 'assets/img/icons/tech.svg';

const getAfterLabelAlertText = (filterValue, filterOption, entity) =>
  filterValue === filterOption ? ` of ${entity}` : ` ${entity}`;

const DEFAULT_DROPDOWNS_VALUE = {
  [INCIDENT_TYPE_FILTER]: null,
  [LOOKER_INCIDENT_TYPE_FILTER]: null,
  [PIPELINE_INCIDENT_TYPE_FILTER]: null,
  [TABLE_ALERT_FILTER]: null,
  [LOOKER_ALERT_FILTER]: null,
  [TECHNOLOGY_FILTER]: null,
  [INCIDENT_STATUS_FILTER]: null,
  [INCIDENT_STATUS_SHORT_FILTER]: null,
};

const Dropdowns = ({
  existingDropdown = [],
  onChangeDropdownsValue = () => {},
  pipelineStats = null,
}) => {
  const { queryParams, updateQueryParams } = useQueryParamsContext();
  const { currentBqBillingData } = useBqBillingData();

  const pipelinesIncidentTypeFilterOptions = useMemo(() => {
    if (!currentBqBillingData?.paymentType) {
      return [];
    }

    const isSlotsBillingData =
      currentBqBillingData.paymentType !== BQ_BILLING_TYPE.ON_DEMANDS;
    const isBytesBillingData =
      currentBqBillingData.paymentType === BQ_BILLING_TYPE.ON_DEMANDS;

    if (isSlotsBillingData) {
      return PIPELINES_BILLED_SLOTS_FILTER_OPTIONS;
    } else if (isBytesBillingData) {
      return PIPELINES_BILLED_BYTES_FILTER_OPTIONS;
    } else {
      return [];
    }
  }, [currentBqBillingData]);

  const technologyFilterOptions = useMemo(() => {
    if (pipelineStats?.length > 0) {
      return [
        INITIAL_TECHNOLOGY_OPTION,
        ...pipelineStats.map((item) => ({
          value: getObjectKeyByValue(PIPELINE_TYPE, item.pipelineType),
          label: getPipelineMetaData(item.pipelineType).title,
        })),
      ];
    }

    return [];
  }, [pipelineStats]);

  const getDefaultDropdownsValueByKey = useCallback(
    (key) => {
      const defaultDropdownsValue = {
        [INCIDENT_TYPE_FILTER]: [STATUS_FILTER_OPTIONS[0]],
        [LOOKER_INCIDENT_TYPE_FILTER]: [LOOKER_STATUS_FILTER_OPTIONS[0]],
        [PIPELINE_INCIDENT_TYPE_FILTER]: [
          pipelinesIncidentTypeFilterOptions[0],
        ],
        [TABLE_ALERT_FILTER]: [
          ALERT_FILTER_OPTIONS[1],
          ALERT_FILTER_OPTIONS[2],
        ],
        [LOOKER_ALERT_FILTER]: [ALERT_FILTER_OPTIONS[0]],
        [TECHNOLOGY_FILTER]: [INITIAL_TECHNOLOGY_OPTION],
        [INCIDENT_STATUS_FILTER]: [INCIDENT_FILTER_OPTIONS[0]],
        [INCIDENT_STATUS_SHORT_FILTER]: [SHORT_INCIDENT_FILTER[0]],
      };

      return defaultDropdownsValue[key] || null;
    },
    [pipelinesIncidentTypeFilterOptions]
  );

  const [dropdownsValue, setDropdownsValue] = useState(DEFAULT_DROPDOWNS_VALUE);

  const afterLabelText = useMemo(() => {
    return {
      looker: getAfterLabelAlertText(
        Array.isArray(dropdownsValue[LOOKER_ALERT_FILTER]) &&
          dropdownsValue[LOOKER_ALERT_FILTER].length > 0
          ? dropdownsValue[LOOKER_ALERT_FILTER][0].value
          : '',
        ALERT_FILTER_OPTIONS[0].value,
        'dashboards'
      ),
      gbq: getAfterLabelAlertText(
        Array.isArray(dropdownsValue[TABLE_ALERT_FILTER]) &&
          dropdownsValue[TABLE_ALERT_FILTER].length > 0
          ? dropdownsValue[TABLE_ALERT_FILTER][0].value
          : '',
        ALERT_FILTER_OPTIONS[0].value,
        'tables'
      ),
    };
  }, [dropdownsValue]);

  useEffect(() => {
    const isDropdownsValueReady = Object.values(dropdownsValue).some(
      (value) => value !== null
    );
    if (!isDropdownsValueReady) {
      return;
    }

    const keysToRemove = Object.keys(dropdownsValue).filter(
      (filterKey) => !existingDropdown.includes(filterKey)
    );

    const newQueryParams = {};
    keysToRemove.forEach((filterKey) => {
      const paramKey = getKeyForURL(filterKey);

      if (queryParams.get(paramKey) !== null) {
        newQueryParams[paramKey] = null;
      }
    });

    Object.keys(dropdownsValue).forEach((filterKey) => {
      if (existingDropdown.includes(filterKey)) {
        const paramValue = dropdownsValue[filterKey]
          ? dropdownsValue[filterKey]
              .map((option) => option.value)
              .join(SEARCH_PARAMS_SEPARATOR)
          : null;
        const paramKey = getKeyForURL(filterKey);

        if (paramValue !== null && paramValue !== queryParams.get(paramKey)) {
          newQueryParams[paramKey] = paramValue;
        }
      }
    });
    if (Object.keys(newQueryParams).length > 0) {
      const useReplace = Object.entries(newQueryParams).some(
        ([key, value]) =>
          value === null || value === undefined || queryParams.get(key) === null
      );

      updateQueryParams(newQueryParams, { useReplace });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownsValue]);

  useEffect(() => {
    const getDropdownValueByFilter = (filterKey, param, options) => {
      let dropdownValueByFilter = null;
      if (existingDropdown.includes(filterKey)) {
        const valueFromURL = queryParams.get(param);

        if (valueFromURL === null) {
          dropdownValueByFilter = getDefaultDropdownsValueByKey(filterKey);
        } else {
          dropdownValueByFilter = getDropdownValueByURL(
            filterKey,
            options,
            valueFromURL
          );
        }
      }

      return dropdownValueByFilter;
    };

    const newDropdownsValue = {};
    newDropdownsValue[TABLE_ALERT_FILTER] = getDropdownValueByFilter(
      TABLE_ALERT_FILTER,
      SEARCH_PARAMS.TABLE_ALERT_TYPE,
      ALERT_FILTER_OPTIONS
    );
    newDropdownsValue[LOOKER_ALERT_FILTER] = getDropdownValueByFilter(
      LOOKER_ALERT_FILTER,
      SEARCH_PARAMS.DASHBOARD_ALERT_TYPE,
      ALERT_FILTER_OPTIONS
    );
    newDropdownsValue[INCIDENT_STATUS_FILTER] = getDropdownValueByFilter(
      INCIDENT_STATUS_FILTER,
      SEARCH_PARAMS.INCIDENT_STATUS,
      INCIDENT_FILTER_OPTIONS
    );
    newDropdownsValue[INCIDENT_TYPE_FILTER] = getDropdownValueByFilter(
      INCIDENT_TYPE_FILTER,
      SEARCH_PARAMS.INCIDENT_TYPE,
      STATUS_FILTER_OPTIONS
    );
    newDropdownsValue[LOOKER_INCIDENT_TYPE_FILTER] = getDropdownValueByFilter(
      LOOKER_INCIDENT_TYPE_FILTER,
      SEARCH_PARAMS.LOOKER_INCIDENT_TYPE,
      LOOKER_STATUS_FILTER_OPTIONS
    );
    newDropdownsValue[INCIDENT_STATUS_SHORT_FILTER] = getDropdownValueByFilter(
      INCIDENT_STATUS_SHORT_FILTER,
      SEARCH_PARAMS.INCIDENT_SHORT_STATUS,
      SHORT_INCIDENT_FILTER
    );

    if (technologyFilterOptions.length > 0) {
      newDropdownsValue[TECHNOLOGY_FILTER] = getDropdownValueByFilter(
        TECHNOLOGY_FILTER,
        SEARCH_PARAMS.PIPELINE_TYPE,
        technologyFilterOptions
      );
    }
    if (pipelinesIncidentTypeFilterOptions.length > 0) {
      newDropdownsValue[PIPELINE_INCIDENT_TYPE_FILTER] =
        getDropdownValueByFilter(
          PIPELINE_INCIDENT_TYPE_FILTER,
          SEARCH_PARAMS.PIPELINE_INCIDENT_TYPE,
          pipelinesIncidentTypeFilterOptions
        );
    }

    setDropdownsValue((prevState) => {
      if (isDropdownsEqual(newDropdownsValue, prevState)) {
        return prevState;
      }

      return newDropdownsValue;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    existingDropdown,
    queryParams,
    technologyFilterOptions,
    pipelinesIncidentTypeFilterOptions,
  ]);

  const handleChangeDropdowns = (key, value) => {
    setDropdownsValue((prevState) => ({ ...prevState, [key]: value }));
  };

  const filteredDropdownsValue = useMemo(() => {
    return Object.keys(dropdownsValue)
      .filter((key) => existingDropdown.includes(key))
      .reduce((obj, key) => {
        obj[key] = dropdownsValue[key];
        return obj;
      }, {});
  }, [dropdownsValue, existingDropdown]);

  useEffect(() => {
    onChangeDropdownsValue(filteredDropdownsValue);
  }, [filteredDropdownsValue, onChangeDropdownsValue]);

  const renderDropdown = (
    filterKey,
    options,
    icon,
    selectAllOption,
    multipleSelect,
    afterLabel
  ) =>
    existingDropdown.includes(filterKey) && (
      <StyledDropdown
        options={options}
        defaultValue={dropdownsValue[filterKey] || []}
        Icon={icon}
        onSelect={(value) => handleChangeDropdowns(filterKey, value)}
        selectAllOption={selectAllOption}
        multipleSelect={multipleSelect}
        afterLabel={afterLabel}
      />
    );

  return (
    <div>
      {renderDropdown(
        LOOKER_ALERT_FILTER,
        ALERT_FILTER_OPTIONS,
        RegularMiniIcon,
        ALERT_FILTER_OPTIONS[0],
        true,
        afterLabelText.looker
      )}
      {renderDropdown(
        TABLE_ALERT_FILTER,
        ALERT_FILTER_OPTIONS,
        RegularMiniIcon,
        ALERT_FILTER_OPTIONS[0],
        true,
        afterLabelText.gbq
      )}
      {technologyFilterOptions.length > 0 &&
        renderDropdown(
          TECHNOLOGY_FILTER,
          technologyFilterOptions,
          TechnologyIcon,
          technologyFilterOptions[0],
          false
        )}
      {renderDropdown(
        INCIDENT_TYPE_FILTER,
        STATUS_FILTER_OPTIONS,
        StatusIcon,
        STATUS_FILTER_OPTIONS[0],
        true
      )}
      {renderDropdown(
        LOOKER_INCIDENT_TYPE_FILTER,
        LOOKER_STATUS_FILTER_OPTIONS,
        StatusIcon,
        LOOKER_STATUS_FILTER_OPTIONS[0],
        true
      )}
      {pipelinesIncidentTypeFilterOptions.length > 0 &&
        renderDropdown(
          PIPELINE_INCIDENT_TYPE_FILTER,
          pipelinesIncidentTypeFilterOptions,
          StatusIcon,
          pipelinesIncidentTypeFilterOptions[0],
          true
        )}
      {renderDropdown(
        INCIDENT_STATUS_FILTER,
        INCIDENT_FILTER_OPTIONS,
        StatusIcon,
        null,
        true,
        ' incidents'
      )}
      {renderDropdown(
        INCIDENT_STATUS_SHORT_FILTER,
        SHORT_INCIDENT_FILTER,
        StatusIcon,
        null,
        true,
        ' incidents'
      )}
    </div>
  );
};

export { Dropdowns };
