import { useQuery, useEffect, useTableFrequency } from 'hooks/hooks.js';
import { Freshness, PositionLoader } from 'Components/components.js';
import { useDateContext } from 'context/context.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';
import { EXISTING_DATA_TYPES } from 'Pages/Monitors/libs/constants.js';

const TableDataFreshness = ({
  table,
  dataset,
  onChangeExistingData,
  isGraphRefreshing = false,
  withGraphTitle = false,
  handleClearRefreshing = null,
}) => {
  const { startDateUtc, endDateUtc } = useDateContext();
  const { frequency, isFrequencyFetching } = useTableFrequency({
    table,
    dataset,
  });

  const { isFetching, data, refetch } = useQuery(
    [QUERY_TYPES.freshness, table, dataset, startDateUtc, endDateUtc],
    ({ queryKey }) => {
      const [url, table, dataset, startDate, endDate] = queryKey;
      return fetcherGet(url, {
        table,
        dataset,
        from: startDate,
        to: endDate,
      });
    },
    { enabled: Boolean(table && dataset), cacheTime: 0 }
  );

  useEffect(() => {
    if (onChangeExistingData) {
      if (data?.values) {
        onChangeExistingData(
          EXISTING_DATA_TYPES.freshness,
          data?.values.length > 0
        );
      } else if (isFetching) {
        onChangeExistingData(EXISTING_DATA_TYPES.freshness, true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.values, isFetching]);

  useEffect(() => {
    if (isGraphRefreshing) {
      refetch().finally(() => handleClearRefreshing && handleClearRefreshing());
    }
  }, [isGraphRefreshing]);

  if (isFetching || isFrequencyFetching) {
    return <PositionLoader />;
  }

  return (
    <>
      {data?.values.length > 0 ? (
        <Freshness
          data={data.values}
          frequency={frequency}
          withGraphTitle={withGraphTitle}
        />
      ) : null}
    </>
  );
};

export { TableDataFreshness };
