import React, { createContext } from 'react';
import { useContext, useLocation, useMemo, useHistory } from 'hooks/hooks.js';

const QueryParamsContext = createContext(null);

const QueryParamsContextProvider = ({ children }) => {
  const history = useHistory();
  const location = useLocation();

  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);

  const updateQueryParams = (newParams, { useReplace = false } = {}) => {
    Object.keys(newParams).forEach((key) => {
      const newValue = newParams[key];

      if (newValue !== undefined && newValue !== null) {
        queryParams.set(key, newValue);
      } else {
        queryParams.delete(key);
      }
    });

    const method = useReplace ? history.replace : history.push;

    method({
      pathname: location.pathname,
      search: queryParams.toString(),
    });
  };

  const clearQueryParams = () => {
    history.push({
      pathname: location.pathname,
      search: '',
    });
  };

  return (
    <QueryParamsContext.Provider
      value={{
        queryParams,
        updateQueryParams,
        clearQueryParams,
      }}
    >
      {children}
    </QueryParamsContext.Provider>
  );
};

const useQueryParamsContext = () => {
  const context = useContext(QueryParamsContext);

  if (!context) {
    throw new Error(
      'useQueryParamsContext must be used within a QueryParamsContextProvider'
    );
  }

  return context;
};

export { QueryParamsContextProvider, useQueryParamsContext };
