import React from 'react';
import clsx from 'clsx';
import { useStyles } from '../IncidentCard.styles.js';
import { Typography, TYPOGRAPHY_COLOR } from 'Components/components.js';

const MetaDataItem = ({
  label = '',
  isBold = false,
  isLargeGap = false,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.column}>
      <Typography
        color={TYPOGRAPHY_COLOR.textSecondary}
        className={clsx(classes.colLabel, isLargeGap && 'isLarge')}
      >
        {label}
      </Typography>

      <Typography className={clsx(classes.colText, isBold && 'isBold')}>
        {children}
      </Typography>
    </div>
  );
};

export { MetaDataItem };
