import { useQuery, useMemo } from 'hooks/hooks.js';
import { fetcherGet } from 'utils/utils.js';
import { QUERY_TYPES } from 'constants/constants.js';

const MILLISECONDS_IN_SECONDS = 1000;

const useTableFrequency = ({ table, dataset }) => {
  const {
    isFetching: isFrequencyFetching,
    data,
    refetch: refetchFrequency,
  } = useQuery(
    [QUERY_TYPES.tableFrequency, dataset, table],
    ({ queryKey }) => {
      const [url, dataset, table] = queryKey;
      return fetcherGet(url, {
        dataset,
        table,
      });
    },
    { enabled: Boolean(dataset && table) }
  );

  const frequency = useMemo(() => {
    return data?.value || null;
  }, [data?.value]);

  const frequencyMilliseconds = useMemo(() => {
    return frequency * MILLISECONDS_IN_SECONDS || null;
  }, [frequency]);

  return {
    frequencyMilliseconds,
    frequency,
    isFrequencyFetching,
    refetchFrequency,
  };
};

export { useTableFrequency };
