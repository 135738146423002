import React from 'react';
import { useEffect, useLocation } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Button, BUTTON_VARIANT } from 'Components/components.js';
import { useQueryParamsContext } from 'context/context.js';
import { amplEvent } from 'service/services.js';
import { SEARCH_PARAMS } from 'constants/constants.js';

const useStyles = makeStyles((theme) => ({
  tabsContainer: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& .tab': {
      marginRight: theme.spacing(4),
      fontSize: 16,
      lineHeight: 'auto',
      fontWeight: 500,
      color: theme.palette.text.primary,
      padding: '0 16px 22px',
      '&--active': {
        color: theme.palette.primary.main,
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 0,
      },
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
}));

const Tabs = ({ tabs, onTabCLick = null, paramsToRemove = [] }) => {
  const classes = useStyles();
  const location = useLocation();
  const { queryParams, updateQueryParams } = useQueryParamsContext();

  const defaultTab = Object.values(tabs)[0];
  const currentTab = queryParams.get(SEARCH_PARAMS.TAB);

  useEffect(() => {
    if (!currentTab || !Object.values(tabs).includes(currentTab)) {
      updateQueryParams(
        { [SEARCH_PARAMS.TAB]: defaultTab },
        { useReplace: true }
      );
    }
  }, [currentTab, defaultTab, tabs, updateQueryParams]);

  useEffect(() => {
    if (currentTab && Object.values(tabs).includes(currentTab)) {
      amplEvent(`${location.pathname} -> tab -> ${currentTab}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab]);

  const handleTabChange = (tab) => {
    if (onTabCLick) {
      onTabCLick(tab);
    }

    const paramsObject = paramsToRemove.reduce((acc, param) => {
      acc[param] = null;
      return acc;
    }, {});

    updateQueryParams({
      [SEARCH_PARAMS.TAB]: tab.toString(),
      ...paramsObject,
    });
  };

  return (
    <div className={classes.tabsContainer}>
      {Object.values(tabs).map((tab) => {
        const isActiveTab = currentTab === tab;

        return (
          <Button
            text={tab.toString()}
            variant={BUTTON_VARIANT.text}
            onClick={() => handleTabChange(tab)}
            key={tab}
            fullWidth={false}
            disableRipple={true}
            className={clsx(
              'txt-mainDark-16-500 tab',
              isActiveTab && 'tab--active'
            )}
          />
        );
      })}
    </div>
  );
};

export { Tabs };
