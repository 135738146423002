import React from 'react';
import { useMemo, useState } from 'hooks/hooks.js';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { CostsStatsWidget } from 'Components/components.js';
import { COSTS_STATS_VIEW_TYPE } from 'constants/constants.js';
import { SavingsOptionsWidget } from './SavingsOptionsWidget.js';

const useStyles = makeStyles((theme) => ({
  flexContainer: {
    display: 'flex',
    gap: theme.spacing(6),
    alignItems: 'start',
    '& > *': {
      width: ({ widgetWidth, viewType }) =>
        viewType === COSTS_STATS_VIEW_TYPE.graph ? '50%' : widgetWidth,
    },
  },
}));

const CostsStats = ({
  costsStats,
  costsStatsPrevious,
  deadEndTablesStats,
  className = '',
  viewType = COSTS_STATS_VIEW_TYPE.table,
}) => {
  const [savingsOptionsWidgetExists, setSavingsOptionsWidgetExists] =
    useState(true);
  const widgetWidth = savingsOptionsWidgetExists ? '50%' : '100%';

  const classes = useStyles({ widgetWidth, viewType });

  const totalAmount = useMemo(() => {
    return costsStats.reduce((acc, { costs }) => acc + costs, 0);
  }, [costsStats]);

  const handleChangeSavingsOptionsWidget = (value) => {
    setSavingsOptionsWidgetExists(value);
  };

  return (
    <div className={clsx(classes.flexContainer, className)}>
      {costsStats.length > 0 && (
        <CostsStatsWidget
          costsStats={costsStats}
          costsStatsPrevious={costsStatsPrevious}
          viewType={viewType}
        />
      )}

      <SavingsOptionsWidget
        totalAmount={totalAmount}
        deadEndTablesStats={deadEndTablesStats}
        onChangeSavingsOptionsWidget={handleChangeSavingsOptionsWidget}
      />
    </div>
  );
};

export { CostsStats };
