import { TABLE_TYPES } from 'constants/constants.js';
import { NodeTypeNames } from '../../enums/enums.js';

const getDatasetTypeName = (datasetType) => {
  switch (datasetType) {
    case TABLE_TYPES.view:
    case TABLE_TYPES.materializedView:
      return NodeTypeNames.VIEW;
    case TABLE_TYPES.external:
      return NodeTypeNames.EXTERNAL;
    default:
      return NodeTypeNames.TABLE;
  }
};

export { getDatasetTypeName };
