import React from 'react';
import { useLocation, useMutation, useQueryClient } from 'hooks/hooks.js';
import { useStyles } from '../../Monitors.styles.js';
import {
  BackButton,
  Typography,
  RadioDropdown,
  DateRangePicker,
  Modal,
  MutedElementModalBody,
  CriticalElementModalBody,
  OverflownText,
} from 'Components/components.js';
import {
  ALERT_TYPES_OPTIONS,
  CRITICAL_ALERT_TYPE,
  MUTED_ALERT_TYPE,
} from 'utils/constants.js';
import { fetcherPost } from 'utils/utils.js';
import {
  QUERY_TYPES,
  AMPL_PAGE_EVENT,
  DAYS_LIMIT,
} from 'constants/constants.js';
import { amplEvent } from 'service/services.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import { useModal, useUserInfo } from 'context/context.js';

const FIAFORMULA_TENANT = 'fe-prod-datalake';

const PageHeader = ({ tableInfo, onChangeTableInfo }) => {
  const classes = useStyles();
  const location = useLocation();
  const { setModal } = useModal();
  const queryClient = useQueryClient();
  const { user } = useUserInfo();

  const isFiaformulaTenant =
    user.info?.tenant === FIAFORMULA_TENANT ||
    user.info?.adminData?.currentTenant === FIAFORMULA_TENANT;

  const rangePickerDaysLimit = isFiaformulaTenant
    ? DAYS_LIMIT.threeMonths
    : DAYS_LIMIT.month;

  const currentAlert =
    ALERT_TYPES_OPTIONS.filter(
      ({ value }) => tableInfo?.alertType === value
    )?.[0] || ALERT_TYPES_OPTIONS[0];

  const onFilterSelect = (alertType) => {
    if (currentAlert.value === alertType.value) {
      return;
    }
    if (currentAlert.value === CRITICAL_ALERT_TYPE) {
      setModal(() => (
        <Modal
          onAgree={() => sendAlertType(alertType.value)}
          title="Are you sure about changing the critical table's status?"
          titlePosition='center'
          ModalBodyComponent={CriticalElementModalBody}
          agreeButtonText='OK'
        />
      ));
    } else if (alertType.value === MUTED_ALERT_TYPE) {
      setModal(() => (
        <Modal
          onAgree={() => sendAlertType(alertType.value)}
          title='Are you sure you want to mute the table?'
          titlePosition='center'
          ModalBodyComponent={MutedElementModalBody}
          agreeButtonText='OK'
        />
      ));
    } else {
      sendAlertType(alertType.value);
    }
  };

  const { mutateAsync: sendAlertType } = useMutation(
    (type) => {
      const tables = [{ dataset: tableInfo?.dataset, table: tableInfo?.table }];
      return fetcherPost(`/api/v1/tables/alerts`, { type, tables });
    },
    {
      onSuccess: (data, variables) => {
        if (data?.success) {
          tableInfo.alertType = variables;
          onChangeTableInfo(tableInfo);
          queryClient.invalidateQueries(QUERY_TYPES.tables);

          amplEvent(
            `${AMPL_PAGE_EVENT.monitors} -> ${getFullTableName(
              tableInfo?.table,
              tableInfo?.dataset
            )} -> change alert type -> ${variables}`
          );
        }
      },
    }
  );

  return (
    <div className={classes.header}>
      <nav className={classes.navContainer}>
        {location?.state?.isShowBackToIncidentsBtn && <BackButton />}
        <div className={classes.backButtons}>
          <Typography
            className={classes.tableTitle}
            component='h3'
            variant='h4'
          >
            <OverflownText title={tableInfo?.table} maxWidth={800}>
              <strong style={{ marginRight: 12 }}>
                {tableInfo?.table ? tableInfo?.table : 'No chosen table'}
              </strong>
            </OverflownText>
            {tableInfo?.table && (
              <RadioDropdown
                options={ALERT_TYPES_OPTIONS}
                current={currentAlert}
                onSelect={onFilterSelect}
                isAlertTypeDropdown={true}
                top={30}
                left={-8}
              />
            )}
          </Typography>
          <Typography component='h3'>
            {tableInfo?.dataset ? `Dataset: ${tableInfo?.dataset}` : ''}
          </Typography>
        </div>
      </nav>

      <DateRangePicker daysLimit={rangePickerDaysLimit} />
    </div>
  );
};

export { PageHeader };
