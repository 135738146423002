import { TABLE_TYPES } from 'constants/constants.js';
import { ReactComponent as PartitionIcon } from 'assets/img/icons/projectTreeIcons/partition.svg';
import { ReactComponent as ViewIcon } from 'assets/img/icons/projectTreeIcons/view20.svg';
import { ReactComponent as MaterializedViewIcon } from 'assets/img/icons/projectTreeIcons/materialized-view20.svg';
import { ReactComponent as ExternalIcon } from 'assets/img/icons/projectTreeIcons/external20.svg';
import { ReactComponent as TableIcon } from 'assets/img/icons/projectTreeIcons/table20.svg';

const iconByTableType = (type, isPartition) => {
  const icons = {
    [TABLE_TYPES.view]: ViewIcon,
    [TABLE_TYPES.materializedView]: MaterializedViewIcon,
    [TABLE_TYPES.external]: ExternalIcon,
    default: TableIcon,
  };

  const Icon = isPartition ? PartitionIcon : icons[type] || icons.default;

  return <Icon style={{ marginRight: 5 }} />;
};

export { iconByTableType };
