import React from 'react';
import { useMemo, useRef } from 'hooks/hooks.js';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import clsx from 'clsx';
import { useStyles } from './Highcharts.style.js';
import {
  Typography,
  TYPOGRAPHY_COLOR,
  TYPOGRAPHY_VARIANT,
} from 'Components/components.js';
import { getUnixDateByStr, numberFormat } from 'utils/helpers/helpers.js';
import { getDatetimeFormat } from './libs/utils.js';
import { GRAPH_HEIGHT } from 'constants/constants.js';
import { GRAPH_NAME, HIGHCHARTS_SETTINGS } from './libs/constants.js';
import projectTheme from 'theme.js';

Highcharts.setOptions(HIGHCHARTS_SETTINGS);

const Scatter = ({ scatterData }) => {
  const classes = useStyles();
  const scatterRef = useRef(null);

  const formattedScatterData = useMemo(() => {
    return scatterData.map(({ datetime, value }) => ({
      x: getUnixDateByStr(datetime),
      y: value,
    }));
  }, [scatterData]);

  const scatterOptions = {
    chart: {
      zooming: {
        type: 'x',
        resetButton: {
          position: {
            x: 0,
            y: -28,
          },
        },
      },
      height: GRAPH_HEIGHT.small,
      backgroundColor: projectTheme.palette.secondary.light,
      borderRadius: 16,
    },
    title: {
      text: GRAPH_NAME.scatter,
      align: 'left',
    },
    xAxis: {
      type: 'datetime',
      labels: {
        format: '{value: %b %d, %I:%M %p}',
        step: 2,
      },
    },
    yAxis: [
      { title: { text: undefined } },
      {
        visible: false,
        max: 1,
        opposite: true,
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      crosshairs: true,
      useHTML: true,
      formatter: function () {
        return `
          <div style="font-size: 0.8em">${getDatetimeFormat(this.x)}</div>
          <span
            style="color: ${Highcharts.getOptions().colors[0]}"
          >
            ●
          </span>raw value: <b>${numberFormat(this.y)}</b>
        `;
      },
    },
    plotOptions: {
      column: {
        states: {
          enabled: false,
          inactive: {
            enabled: false,
          },
        },
      },
      scatter: {
        states: {
          inactive: {
            enabled: false,
          },
        },
      },
      series: {
        marker: {
          fillColor: projectTheme.palette.divider,
          radius: 2,
          states: {
            hover: {
              fillColor: Highcharts.getOptions().colors[0],
              lineWidthPlus: 0,
              radiusPlus: 0,
            },
            select: {
              fillColor: Highcharts.getOptions().colors[0],
              lineWidth: 0,
            },
          },
        },
        states: {
          hover: { halo: null },
          select: { halo: null },
        },
      },
    },
    series: [
      {
        type: 'scatter',
        data: formattedScatterData,
        name: 'raw value',
      },
    ],
  };

  return (
    <div className={clsx(classes.container, 'zoom-effects')}>
      <HighchartsReact
        ref={scatterRef}
        highcharts={Highcharts}
        options={scatterOptions}
      />

      <Typography
        variant={TYPOGRAPHY_VARIANT.body2}
        color={TYPOGRAPHY_COLOR.textSecondary}
        className='text-right mt-1'
      >
        *Select section to zoom in
      </Typography>
    </div>
  );
};

export { Scatter };
