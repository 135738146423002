import moment from 'moment/moment';

const MAX_PERCENT = 100;

const getAreaDataWithCallback = (data, getInterval) => {
  return data.map((item) => ({
    ...item,
    value: item.value === null && item.isAnomaly ? 0 : item.value,
    interval: getInterval(item),
  }));
};

const getAreaData = (data) => {
  const getIntervalCallback = (item) => {
    return (item.min === 0 || item.min === null) &&
      (item.max === 0 || item.max === null)
      ? [0, 0]
      : [item.min > 0 ? item.min : 1, item.max > 0 ? item.max : 1];
  };

  return getAreaDataWithCallback(data, getIntervalCallback);
};

const getAreaDataWithThresholdCoefficient = (data, percentThreshold) => {
  const thresholdCoefficient = percentThreshold / MAX_PERCENT;
  const getIntervalCallback = (item) => {
    const minInterval = item.minPredicted
      ? Math.round(
          item.minRaw - (item.minPredicted - item.minRaw) * thresholdCoefficient
        )
      : item.minRaw;
    const maxInterval = item.maxPredicted
      ? Math.round(
          item.maxRaw + (item.maxRaw - item.maxPredicted) * thresholdCoefficient
        )
      : item.maxRaw;

    return [
      minInterval > 0 ? minInterval : 1,
      maxInterval > 0 ? maxInterval : 1,
    ];
  };

  return getAreaDataWithCallback(data, getIntervalCallback);
};

const getDatetimeFormat = (datetime) => {
  return moment(datetime).utc().format('MMM DD, hh:mm a');
};

export { getAreaData, getAreaDataWithThresholdCoefficient, getDatetimeFormat };
