import {
  useJobVolume,
  useJobFrequency,
  useMemo,
  useEffect,
} from 'hooks/hooks.js';
import { Volume, PositionLoader } from 'Components/components.js';
import { getAreaData } from './utils.js';
import { GRAPH_HEIGHT } from 'constants/constants.js';

const PipelineDataVolume = ({
  jobHash,
  metricType,
  onLoadingVolumeDataChange,
  currentDatetime = null,
  isGraphRefreshing = false,
  handleClearRefreshing = null,
  volumeHeight = GRAPH_HEIGHT.large,
}) => {
  const { volumeData, isLoadingVolumeData, refetchVolumeData } =
    useJobVolume(jobHash);
  const { frequencyMilliseconds, refetchFrequency } = useJobFrequency(jobHash);

  const volumeAreaData = useMemo(() => {
    if (volumeData.length > 0) {
      if (volumeData.every((item) => item.value === null)) {
        return [];
      } else {
        return getAreaData(volumeData);
      }
    }

    return [];
  }, [volumeData]);

  useEffect(() => {
    if (onLoadingVolumeDataChange) {
      onLoadingVolumeDataChange(isLoadingVolumeData);
    }
  }, [isLoadingVolumeData, onLoadingVolumeDataChange]);

  useEffect(() => {
    if (isGraphRefreshing) {
      refetchFrequency()
        .then(() => refetchVolumeData())
        .finally(() => handleClearRefreshing && handleClearRefreshing());
    }
  }, [isGraphRefreshing]);

  if (isLoadingVolumeData) {
    return <PositionLoader />;
  }

  if (!isLoadingVolumeData && !volumeAreaData.length) {
    return (
      <div className='txt-grey-13-500 text-center text-italic'>
        No data for graph
      </div>
    );
  }

  return (
    <>
      {volumeData.length > 0 && (
        <Volume
          volumeData={volumeAreaData}
          frequencyMilliseconds={frequencyMilliseconds}
          currentDatetime={currentDatetime}
          metricType={metricType}
          volumeHeight={volumeHeight}
        />
      )}
    </>
  );
};

export { PipelineDataVolume };
