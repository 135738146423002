import Highcharts from 'highcharts';
import moment from 'moment/moment';
import { getIncidentFormatData } from 'utils/helpers/helpers.js';
import { METRIC_TYPE } from 'constants/constants.js';

const FILTERED_DATA_TYPE = 'PERCENTAGE';

const getDatetimeFormat = (datetime) => {
  return moment(datetime).utc().format('MMM DD, hh:mm a');
};

const getIntervalTitle = (interval = [], metricType = null) => {
  if (interval.length) {
    const firstValue = interval.at(0);
    const lastValue = interval.at(-1);

    if (firstValue !== 0 && lastValue !== 0) {
      return `${getIncidentFormatData(
        metricType,
        firstValue
      )} - ${getIncidentFormatData(metricType, lastValue)}`;
    }
  }

  return 'No data available';
};

const createFilteredDataTooltipItemName = (
  minFilteredData,
  maxFilteredData
) => {
  let name = 'custom bar';

  const isPercentageFilter = (data) =>
    data && data.filterType === FILTERED_DATA_TYPE;

  if (isPercentageFilter(maxFilteredData) && !minFilteredData) {
    name = `custom bar (${maxFilteredData.filterValue}%)`;
  } else if (isPercentageFilter(minFilteredData) && !maxFilteredData) {
    name = `custom bar (${minFilteredData.filterValue}%)`;
  } else if (
    isPercentageFilter(maxFilteredData) &&
    isPercentageFilter(minFilteredData) &&
    maxFilteredData.filterValue === minFilteredData.filterValue
  ) {
    name = `custom bar (${maxFilteredData.filterValue}%)`;
  }

  return name;
};

const createFilteredDataTooltipItemValue = (
  minFilteredData,
  maxFilteredData,
  metricType
) => {
  const formatData = (data) =>
    data
      ? getIncidentFormatData(metricType, data.value > 0 ? data.value : 0)
      : '';
  const isPercentageFilter = (data) =>
    data && data.filterType === FILTERED_DATA_TYPE;

  let value = 'No data available';

  if (maxFilteredData && minFilteredData) {
    const maxIsPercentage = isPercentageFilter(maxFilteredData);
    const minIsPercentage = isPercentageFilter(minFilteredData);

    if (maxIsPercentage !== minIsPercentage) {
      value = `${formatData(minFilteredData)}${
        minIsPercentage ? ` (${minFilteredData.filterValue}%)` : ''
      } - ${formatData(maxFilteredData)}${
        maxIsPercentage ? ` (${maxFilteredData.filterValue}%)` : ''
      }`;
    } else {
      value = `${formatData(minFilteredData)} - ${formatData(maxFilteredData)}`;
    }
  } else if (maxFilteredData && !minFilteredData) {
    value = `${formatData(maxFilteredData)}`;
  } else if (minFilteredData && !maxFilteredData) {
    value = `${formatData(minFilteredData)}`;
  }

  return value;
};

const getTitleByMetricType = (metricType) => {
  switch (metricType) {
    case METRIC_TYPE.BILLED_BYTES:
      return 'Bytes, consumed';
    case METRIC_TYPE.SLOTS_MS:
      return 'Slots ms, consumed';
    case METRIC_TYPE.DURATION:
      return 'Milliseconds, duration';
    default:
      return undefined;
  }
};

const getScatterElementOptions = (data = []) => ({
  type: 'scatter',
  data,
  marker: {
    symbol: 'circle',
    radius: 4,
    fillColor: Highcharts.getOptions().colors[0],
    lineWidth: 5,
    lineColor: 'rgba(47, 80, 255, 0.3)',
  },
  states: {
    inactive: { enabled: false },
  },
});

export {
  getDatetimeFormat,
  getIntervalTitle,
  createFilteredDataTooltipItemName,
  createFilteredDataTooltipItemValue,
  getTitleByMetricType,
  getScatterElementOptions,
};
