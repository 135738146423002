import React from 'react';
import { Redirect } from 'react-router-dom';
import {
  useEffect,
  useState,
  useHistory,
  useLocation,
  useCallback,
  useMemo,
} from 'hooks/hooks.js';
import { useGetExistingProjects } from './libs/hooks/hooks.js';
import axios from 'axios';
import { Button } from 'Components/components.js';
import {
  SetUpProject,
  InputProject,
  SetUpLoading,
  CheckPermissionsLoading,
} from './components/components.js';
import { useSignUpInfo, useUserInfo } from 'context/context.js';
import { AppRoutes } from 'app-routes.js';
import { fetcherPost } from 'utils/utils.js';
import {
  INPUT_VALIDATION,
  INPUT_VALUE_ERRORS,
} from './libs/constants/constants.js';
import { auth, signUpAmplEvent } from 'service/services.js';
import {
  ALERT_SEVERITY,
  AUTH_ERRORS,
  DOCUMENT_TITLE,
  SEARCH_PARAMS,
} from 'constants/constants.js';

const TRACKER_EVENT = 'Manual Deployment (Add GCP Project ID)';
const EVENT_BUTTON_CONNECT = `${TRACKER_EVENT} -> Click -> Check permissions and connect`;

const CreateFormComponent = ({
  projectValue,
  handleChangeProject,
  onConnectClick,
  buttonText,
  isReadOnlyInputProject = false,
}) => {
  return (
    <div className='form'>
      <div className='formBlock'>
        <InputProject
          projectValue={projectValue}
          onProjectValueChange={handleChangeProject}
          isReadOnlyInputProject={isReadOnlyInputProject}
        />
      </div>

      <Button
        onClick={onConnectClick}
        text={buttonText}
        fullWidth={false}
        className='align-self-center mt-4 pe-4 ps-4'
      />
    </div>
  );
};

const CreateTextBlockComponent = () => {
  return (
    <div>
      Let’s connect your Google Cloud project, that you want to observe. Ensure
      that you
      <br />
      have created the necessary resources within your project to allow Masthead
      to
      <br />
      monitor your data assets and pipelines.&nbsp;
      <a
        href='https://docs.mastheadata.com/saas-manual-resource-creation-google-cloud-+-bigquery'
        target='_blank'
        rel='noreferrer'
        className='link'
      >
        Read Deployment Guidelines
      </a>
    </div>
  );
};

const DeploymentAddProject = () => {
  const { signUpInfo, setSignUpInfo, setSignUpError } = useSignUpInfo();
  const { setUser, setAuthError } = useUserInfo();
  const history = useHistory();
  const [checkPermissionsLoading, setCheckPermissionsLoading] = useState(false);
  const [setUpLoading, setSetUpLoading] = useState(false);
  const [projectValue, setProjectValue] = useState('');
  const [error, setError] = useState(null);
  const [severity, setSeverity] = useState(ALERT_SEVERITY.error);
  const [isOnlyDisabledRetroStatus, setIsOnlyDisabledRetroStatus] =
    useState(false);
  const location = useLocation();

  const requestData = useMemo(() => {
    return {
      adminEmail: signUpInfo?.user.email,
      project: projectValue,
      name: signUpInfo?.name,
      company: signUpInfo?.company,
      companyRole: signUpInfo?.companyRole,
      companyUseCase: signUpInfo?.companyUseCase,
      marketplaceAccountId: signUpInfo?.marketplaceAccountId,
    };
  }, [projectValue, signUpInfo]);

  useEffect(() => {
    document.title = DOCUMENT_TITLE.deployment;
  }, []);

  useEffect(() => {
    signUpAmplEvent(
      TRACKER_EVENT,
      signUpInfo?.accessToken,
      signUpInfo?.name,
      signUpInfo?.company,
      signUpInfo?.companyUseCase
    );
  }, [signUpInfo]);

  const handleChange = useCallback((value) => {
    if (value.length === INPUT_VALIDATION.maxLength) {
      setError(INPUT_VALUE_ERRORS.limitCharacters);
    } else {
      setError(null);
    }

    setProjectValue(value.replace(' ', ''));
  }, []);

  const token = signUpInfo?.accessToken;
  const { existingProjects, error: existingProjectsError } =
    useGetExistingProjects(token);

  useEffect(() => {
    if (existingProjectsError !== null) {
      setError(existingProjectsError);
    } else {
      setError(null);
    }
  }, [existingProjectsError]);

  if (!signUpInfo) {
    const searchParams = new URLSearchParams(location.search);
    const marketplaceId = searchParams.get('marketplaceId');

    const queryString = marketplaceId ? `?marketplaceId=${marketplaceId}` : '';
    return <Redirect to={`${AppRoutes.SignUp.path}${queryString}`} />;
  }

  const validateProject = () => {
    if (!projectValue) {
      setError(INPUT_VALUE_ERRORS.addGcp);
      return false;
    }
    if (existingProjects.includes(projectValue)) {
      setError(AUTH_ERRORS.EXISTING_PROJECT);
      return false;
    }

    return true;
  };

  const createProject = () => {
    if (!validateProject()) {
      return;
    }
    setSetUpLoading(true);
    setCheckPermissionsLoading(false);

    fetcherPost('/auth/sign-up', requestData)
      .then((data) => {
        if (data?.value) {
          setSetUpLoading(false);
          const user = signUpInfo.user;
          user.info = data.value;
          setUser(user);
          signUpAmplEvent(
            `${EVENT_BUTTON_CONNECT} (success)`,
            signUpInfo.accessToken,
            signUpInfo.name,
            signUpInfo.company,
            signUpInfo.companyUseCase
          );
          auth.updateCurrentUser(user).then();
          history.push(
            `${AppRoutes.Dashboard.path}?${SEARCH_PARAMS.CREATE_PROJECT}=success`
          );
        } else {
          setSignUpError(AUTH_ERRORS.STH_ELSE);
          signUpAmplEvent(
            `${EVENT_BUTTON_CONNECT} (error_1)`,
            signUpInfo.accessToken,
            signUpInfo.name,
            signUpInfo.company,
            signUpInfo.companyUseCase
          );
        }
      })
      .catch((e) => {
        setSignUpError(AUTH_ERRORS.STH_ELSE);
        signUpAmplEvent(
          `${EVENT_BUTTON_CONNECT} (error_2 - ${e.message})`,
          signUpInfo.accessToken,
          signUpInfo.name,
          signUpInfo.company,
          signUpInfo.companyUseCase
        );
      })
      .finally(() => {
        setSetUpLoading(false);
        setSignUpInfo(null);
      });
  };

  const saveValue = () => {
    if (!validateProject()) {
      return;
    }
    setError(null);
    setCheckPermissionsLoading(true);

    fetcherPost('/auth/sign-up/validate', requestData)
      .then((data) => {
        if (data?.success) {
          signUpAmplEvent(
            `${EVENT_BUTTON_CONNECT} Validate (success)`,
            signUpInfo.accessToken,
            signUpInfo.name,
            signUpInfo.company,
            signUpInfo.companyUseCase
          );
        } else {
          let errorMessage = AUTH_ERRORS.STH_ELSE;
          if (data?.error) {
            errorMessage = data?.error;
          }
          setError(errorMessage);
          signUpAmplEvent(
            `${EVENT_BUTTON_CONNECT} Validate (error_1 - ${errorMessage})`,
            signUpInfo.accessToken,
            signUpInfo.name,
            signUpInfo.company,
            signUpInfo.companyUseCase
          );

          setCheckPermissionsLoading(false);
          setProjectValue('');
          return;
        }
      })
      .catch((e) => {
        setError(AUTH_ERRORS.STH_ELSE);
        signUpAmplEvent(
          `${EVENT_BUTTON_CONNECT} Validate (error_2 - ${e.message})`,
          signUpInfo.accessToken,
          signUpInfo.name,
          signUpInfo.company,
          signUpInfo.companyUseCase
        );

        setCheckPermissionsLoading(false);
        setProjectValue('');
        return;
      });

    signUpInfo.project = projectValue;

    axios
      .get(`/openApi/v1/project/${projectValue}/status`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then(({ data }) => {
        let errorMessage = null;

        if (!data.schemaStatus && !data.subscriptionStatus) {
          errorMessage = INPUT_VALUE_ERRORS.combineError(projectValue);
        } else {
          if (!data.schemaStatus) {
            errorMessage = INPUT_VALUE_ERRORS.schemaStatus(projectValue);
          } else if (!data.subscriptionStatus) {
            errorMessage = INPUT_VALUE_ERRORS.subscriptionStatus;
          }

          if (
            data.schemaStatus &&
            data.subscriptionStatus &&
            !data.retroStatus
          ) {
            setIsOnlyDisabledRetroStatus(true);
            setError(INPUT_VALUE_ERRORS.retroStatus(projectValue));
            setSeverity(ALERT_SEVERITY.info);
            setCheckPermissionsLoading(false);

            return;
          }
        }

        if (!errorMessage) {
          createProject();
        } else {
          setError(errorMessage);
          setCheckPermissionsLoading(false);
          setProjectValue('');
          signUpAmplEvent(
            `${EVENT_BUTTON_CONNECT} (error_3 - Missing resources or insufficient permissions)`,
            signUpInfo.accessToken,
            signUpInfo.name,
            signUpInfo.company,
            signUpInfo.companyUseCase
          );
          return;
        }
      })
      .catch((e) => {
        setSignUpError(e.message);
        setCheckPermissionsLoading(false);
        setSetUpLoading(false);
        setSignUpInfo(null);
        setAuthError(null);
        signUpAmplEvent(
          `${EVENT_BUTTON_CONNECT} (error_4 - ${e.message})`,
          signUpInfo.accessToken,
          signUpInfo.name,
          signUpInfo.company,
          signUpInfo.companyUseCase
        );
      });
  };

  if (checkPermissionsLoading) {
    return <CheckPermissionsLoading />;
  }
  if (setUpLoading) {
    return <SetUpLoading />;
  }

  return (
    <SetUpProject
      error={error}
      severity={severity}
      title='Add Google Cloud Project'
      FormComponent={CreateFormComponent}
      TextBlockComponent={CreateTextBlockComponent}
      handleChangeProject={handleChange}
      onConnectClick={isOnlyDisabledRetroStatus ? createProject : saveValue}
      projectValue={projectValue}
      buttonText={
        isOnlyDisabledRetroStatus
          ? 'Proceed anyway'
          : 'Check permissions and connect'
      }
      isReadOnlyInputProject={isOnlyDisabledRetroStatus}
    />
  );
};

export default DeploymentAddProject;
