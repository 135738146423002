import { makeStyles } from '@mui/styles';
import { MAIN_PAGE_MARGIN } from 'constants/constants.js';

export const useStyles = makeStyles((theme) => ({
  mainContainer: {
    position: 'relative',
    display: 'flex',
    height: `calc(100vh - ${MAIN_PAGE_MARGIN * 2}px)`,
    maxHeight: `calc(100vh - ${MAIN_PAGE_MARGIN * 2}px)`,
    borderRadius: 12,
    overflow: 'scroll',
  },
  largeBlock: {
    padding: '32px 24px',
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    backgroundColor: theme.palette.common.white,
  },
  scrollContainer: {
    maxHeight: '100%',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      width: 0,
    },
  },
  smallBlock: {
    width: '38%',
    backgroundColor: theme.palette.secondary.main,
    borderBottomRightRadius: 12,
  },
  image: {
    position: 'absolute',
    top: '50%',
    right: 0,
    width: '46%',
    transform: 'translateY(-50%)',
  },
}));
