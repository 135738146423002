import { PIPELINE_TYPE } from 'utils/constants.js';
import CustomPipelineIcon from 'assets/img/icons/dashboard-icons/CustomPipelineIcon.svg';
import DbtIcon from 'assets/img/icons/dashboard-icons/dbt-icon.svg';
import DataformIcon from 'assets/img/icons/dashboard-icons/dataform.svg';
import FivetranIcon from 'assets/img/icons/dashboard-icons/fivetran-icon.svg';
import DTSIcon from 'assets/img/icons/pipelines-icons/data-transfer-service-icon.svg';
import LookerIcon from 'assets/img/looker.svg';
import DataddoIcon from 'assets/img/dataddo.svg';
import TableauIcon from 'assets/img/tableau.svg';
import DomoIcon from 'assets/img/icons/pipelines-icons/domo-icon.svg';
import StitchIcon from 'assets/img/icons/pipelines-icons/stitch.svg';
import AirbyteIcon from 'assets/img/icons/pipelines-icons/airbyte.png';
import PowerBIIcon from 'assets/img/icons/pipelines-icons/power-bi-icon.svg';
import UnknownPipelineIcon from 'assets/img/icons/pipelines-icons/unknown-pipeline-icon.svg';
import EppoIcon from 'assets/img/icons/pipelines-icons/eppo-icon.svg';
import BigQueryIcon from 'assets/img/technologies/bigquery-logo.svg';
import AirflowIcon from 'assets/img/icons/pipelines-icons/airflow.svg';
import DeepnoteIcon from 'assets/img/icons/pipelines-icons/deepnote.svg';
import HexIcon from 'assets/img/icons/pipelines-icons/hex.svg';
import MetaplaneIcon from 'assets/img/icons/pipelines-icons/metaplane.svg';
import CensusIcon from 'assets/img/icons/pipelines-icons/census.svg';
import PocusIcon from 'assets/img/icons/pipelines-icons/pocus.svg';
import AmplitudeIcon from 'assets/img/icons/pipelines-icons/amplitude.svg';
import HightouchIcon from 'assets/img/icons/pipelines-icons/hightouch.svg';
import CloudDataFusion from 'assets/img/icons/pipelines-icons/cloud-data-fusion.svg';

const getPipelineMetaData = (pipelineType) => {
  switch (pipelineType) {
    case PIPELINE_TYPE.BROWSER:
      return {
        title: 'BigQuery console',
        icon: BigQueryIcon,
        colorType: '#A1E1FF',
      };
    case PIPELINE_TYPE.CUSTOM:
      return {
        title: 'Custom pipeline',
        icon: CustomPipelineIcon,
        colorType: '#95A1FC',
      };
    case PIPELINE_TYPE.DBT:
      return {
        title: 'DBT',
        icon: DbtIcon,
        colorType: '#59B6AB',
      };
    case PIPELINE_TYPE.FIVETRAN:
      return {
        title: 'Fivetran',
        icon: FivetranIcon,
        colorType: '#FFA1A1',
      };
    case PIPELINE_TYPE.DATAFORM:
      return {
        title: 'Dataform',
        icon: DataformIcon,
        colorType: '#BBEEED',
      };
    case PIPELINE_TYPE.BQ_DTS:
      return {
        title: 'Data Transfer Service',
        icon: DTSIcon,
        colorType: '#D1BBFF',
      };
    case PIPELINE_TYPE.LOOKER:
      return {
        title: 'Looker',
        icon: LookerIcon,
        colorType: '#B2E229',
      };
    case PIPELINE_TYPE.DOMO:
      return {
        title: 'Domo',
        icon: DomoIcon,
        colorType: '#90BAF9',
      };
    case PIPELINE_TYPE.TABLEAU:
      return {
        title: 'Tableau',
        icon: TableauIcon,
        colorType: '#FFCEA1',
      };
    case PIPELINE_TYPE.DATADDO:
      return {
        title: 'Dataddo',
        icon: DataddoIcon,
        colorType: '#B6B0FB',
      };
    case PIPELINE_TYPE.STITCH:
      return {
        title: 'Stitch',
        icon: StitchIcon,
        colorType: '#FFC1E3',
      };
    case PIPELINE_TYPE.AIRBYTE:
      return {
        title: 'Airbyte',
        icon: AirbyteIcon,
        colorType: '#A1F7D1',
      };
    case PIPELINE_TYPE.POWER_BI:
      return {
        title: 'Power BI',
        icon: PowerBIIcon,
        colorType: '#FFC8A2',
      };
    case PIPELINE_TYPE.EPPO:
      return {
        title: 'Eppo',
        icon: EppoIcon,
        colorType: '#A1FFC1',
      };
    case PIPELINE_TYPE.AIRFLOW:
      return {
        title: 'Airflow',
        icon: AirflowIcon,
        colorType: '#FFA3E2',
      };
    case PIPELINE_TYPE.DEEPNOTE:
      return {
        title: 'Deepnote',
        icon: DeepnoteIcon,
        colorType: '#FFE8A1',
      };
    case PIPELINE_TYPE.HEX:
      return {
        title: 'Hex',
        icon: HexIcon,
        colorType: '#95DAFF',
      };
    case PIPELINE_TYPE.METAPLANE:
      return {
        title: 'Metaplane',
        icon: MetaplaneIcon,
        colorType: '#A6A1FF',
      };
    case PIPELINE_TYPE.CENSUS:
      return {
        title: 'Census',
        icon: CensusIcon,
        colorType: '#FFC4D9',
      };
    case PIPELINE_TYPE.POCUS:
      return {
        title: 'Pocus',
        icon: PocusIcon,
        colorType: '#C3A1FF',
      };
    case PIPELINE_TYPE.AMPLITUDE:
      return {
        title: 'Amplitude',
        icon: AmplitudeIcon,
        colorType: '#A1C8FF',
      };
    case PIPELINE_TYPE.HIGHTOUCH:
      return {
        title: 'Hightouch',
        icon: HightouchIcon,
        colorType: '#B5FFA1',
      };
    case PIPELINE_TYPE.CLOUD_DATA_FUSION:
      return {
        title: 'Data Fusion',
        icon: CloudDataFusion,
        colorType: '#FFA1D6',
      };
    default:
      return {
        title: 'Unknown pipeline',
        icon: UnknownPipelineIcon,
        colorType: '#A2E2FF',
      };
  }
};

export { getPipelineMetaData };
