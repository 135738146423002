import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import './scss/volt.scss';
import App from './Main';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import './utils/axiosConfig';
import {
  BannerContextProvider,
  ConfigurationContextProvider,
  ModalContextProvider,
  ProjectTreeContextProvider,
  SignUpContextProvider,
  SnackbarContextProvider,
  UserContextProvider,
  DateContextProvider,
  QueryParamsContextProvider,
} from 'context/context';
import store from 'store/store.js';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <QueryParamsContextProvider>
            <DateContextProvider>
              <SnackbarContextProvider>
                <UserContextProvider>
                  <ModalContextProvider>
                    <ConfigurationContextProvider>
                      <BannerContextProvider>
                        <SignUpContextProvider>
                          <ProjectTreeContextProvider>
                            <App />
                          </ProjectTreeContextProvider>
                        </SignUpContextProvider>
                      </BannerContextProvider>
                    </ConfigurationContextProvider>
                  </ModalContextProvider>
                </UserContextProvider>
              </SnackbarContextProvider>
            </DateContextProvider>
          </QueryParamsContextProvider>
        </ThemeProvider>
      </Provider>
    </QueryClientProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
