import React from 'react';
import {
  useCallback,
  useEffect,
  useState,
  useHistory,
  useLocation,
  usePagination,
  useSelector,
  useMemo,
  useBooleanToggle,
} from 'hooks/hooks.js';
import { useStyles } from './Dictionary.styles.js';
import {
  Backdrop,
  PageHeader,
  Pagination,
  StyledDropdown,
  SuggestedPriority,
  Tabs,
} from 'Components/components.js';
import {
  DictionaryTable,
  DictionaryDataset,
  DictionaryColumn,
  DictionaryLooker,
} from './components/components.js';
import {
  ALERT_FILTER_OPTIONS,
  DICTIONARY_LOOKER_TYPES_OPTIONS,
  DICTIONARY_TYPES_OPTIONS,
  DICTIONARY_TYPES_STREAM,
} from 'utils/constants.js';
import { AppRoutes } from 'app-routes.js';
import {
  AMPL_PAGE_EVENT,
  SEARCH_PARAMS,
  DictionaryTabs,
  DOCUMENT_TITLE,
} from 'constants/constants.js';
import { getFullTableName } from 'utils/helpers/helpers.js';
import { amplEvent } from 'service/services.js';
import { useConfiguration, useQueryParamsContext } from 'context/context.js';
import { ReactComponent as DictionaryTypeIcon } from 'assets/img/dictionaryIcons/typeIcon.svg';
import { ReactComponent as DictionaryStreamIcon } from 'assets/img/dictionaryIcons/showIcon.svg';
import { RegularMiniIcon } from 'assets/img/alert';

const EVENT_CLICK = `${AMPL_PAGE_EVENT.dictionary} -> Tab`;
const PARAMS_TO_REMOVE = [
  SEARCH_PARAMS.TABLE,
  SEARCH_PARAMS.DATASET,
  SEARCH_PARAMS.SEARCH,
];

const filterLookerStateOptions = (stateFilterLookerIds) => {
  return DICTIONARY_LOOKER_TYPES_OPTIONS.filter(({ value }) =>
    stateFilterLookerIds.includes(value)
  );
};

const Dictionary = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const { isLookerConnected } = useConfiguration();
  const { queryParams } = useQueryParamsContext();

  const isStateSuggestedPriority = location.state?.isSuggestedPriority || false;

  const filterLookerOptions = filterLookerStateOptions(
    location.state?.filterLookerIds !== undefined
      ? location.state?.filterLookerIds
      : []
  );
  const [filterAlert, setFilterAlert] = useState([ALERT_FILTER_OPTIONS[0]]);
  const [filterLookerAlert, setFilterLookerAlert] = useState([
    ALERT_FILTER_OPTIONS[0],
  ]);
  const [filterType, setFilterType] = useState([DICTIONARY_TYPES_OPTIONS[0]]);
  const [filterLookerType, setFilterLookerType] = useState(
    filterLookerOptions.length
      ? filterLookerOptions
      : [DICTIONARY_LOOKER_TYPES_OPTIONS[0]]
  );
  const [filterTypeStream, setFilterTypeStream] = useState([
    DICTIONARY_TYPES_STREAM[0],
  ]);

  const [isOpenBackdrop, setIsOpenBackdrop] = useState(false);

  const currentTab = queryParams.get(SEARCH_PARAMS.TAB);

  const existingDropdown = useMemo(() => {
    return {
      tableAlert:
        currentTab === DictionaryTabs.TABLES ||
        currentTab === DictionaryTabs.DATASETS,
      stream: currentTab === DictionaryTabs.TABLES,
      tableType: currentTab === DictionaryTabs.TABLES,
      reportsType: currentTab === DictionaryTabs.LOOKER && isLookerConnected,
      lookerAlertType:
        currentTab === DictionaryTabs.LOOKER && isLookerConnected,
      suggestedCritical: currentTab === DictionaryTabs.TABLES,
    };
  }, [currentTab, isLookerConnected]);

  const [totalNumber, setTotalNumber] = useState(0);
  const { page, rowsPerPage, handleChangePage, handleChangeRowsPerPage } =
    usePagination();
  const { booleanState: isSuggestedPriority, handleChangeBooleanState } =
    useBooleanToggle(isStateSuggestedPriority);

  const { tablesNumber, columnsNumber, lookerNumber } = useSelector(
    (state) => ({
      tablesNumber: state.tables.tablesNumber,
      columnsNumber: state.columns.columnsNumber,
      lookerNumber: state.looker.lookerNumber,
    })
  );

  useEffect(() => {
    document.title = DOCUMENT_TITLE.dictionary;
    amplEvent(AMPL_PAGE_EVENT.dictionary);
  }, []);

  useEffect(() => {
    if (isSuggestedPriority) {
      amplEvent(`${EVENT_CLICK} Tables -> 'Suggested to prioritise' selected`);
    }
  }, [isSuggestedPriority]);

  useEffect(() => {
    const numberMapping = {
      [DictionaryTabs.TABLES]: tablesNumber,
      [DictionaryTabs.COLUMNS]: columnsNumber,
      [DictionaryTabs.LOOKER]: lookerNumber,
    };

    setTotalNumber(numberMapping[currentTab]);
  }, [tablesNumber, columnsNumber, lookerNumber, currentTab]);

  const clearLocationState = () => {
    if (location.state?.isSuggestedPriority) {
      delete location.state.isSuggestedPriority;
      history.replace({ state: location.state, ...history.location });
    }
  };

  const handleChangeSelectedSuggested = () => {
    handleChangeBooleanState();
    clearLocationState();
  };

  const handleChangeTotalNumber = useCallback((value) => {
    setTotalNumber(value);
  }, []);

  const handleChangeBackdrop = useCallback((value) => {
    setIsOpenBackdrop(value);
  }, []);

  const renderPage = () => {
    switch (currentTab) {
      case DictionaryTabs.COLUMNS:
        return (
          <DictionaryColumn
            onMonitors={onMonitors}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onChangeBackdrop={handleChangeBackdrop}
          />
        );
      case DictionaryTabs.DATASETS:
        return (
          <DictionaryDataset
            onDatasetClick={onDatasetClick}
            filterAlert={filterAlert}
            setFilterAlert={setFilterAlert}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onReceiveTotalNumber={handleChangeTotalNumber}
            onChangeBackdrop={handleChangeBackdrop}
          />
        );
      case DictionaryTabs.LOOKER:
        return (
          <DictionaryLooker
            filterLookerAlert={filterLookerAlert}
            filterLookerType={filterLookerType}
            onMonitors={onMonitors}
            isShowLookerTable={isLookerConnected}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onChangeBackdrop={handleChangeBackdrop}
          />
        );
      default:
        return (
          <DictionaryTable
            filterType={filterType}
            filterAlert={filterAlert}
            filterTypeStream={filterTypeStream}
            isSuggestedPriority={isSuggestedPriority}
            onMonitors={onMonitors}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            onChangeBackdrop={handleChangeBackdrop}
          />
        );
    }
  };

  const clearFilters = () => {
    setFilterAlert([ALERT_FILTER_OPTIONS[0]]);
    setFilterLookerAlert([ALERT_FILTER_OPTIONS[0]]);
    setFilterTypeStream([DICTIONARY_TYPES_STREAM[0]]);
    setFilterType([DICTIONARY_TYPES_OPTIONS[0]]);
    setFilterLookerType([DICTIONARY_LOOKER_TYPES_OPTIONS[0]]);
  };
  const onFilterAlertSelect = (values) => {
    handleChangePage({}, 0);
    setFilterAlert(values);
  };
  const onFilterLookerAlertSelect = (values) => {
    handleChangePage({}, 0);
    setFilterLookerAlert(values);
  };
  const onFilterStreamSelect = (values) => {
    handleChangePage({}, 0);
    setFilterTypeStream(values);
  };
  const onFilterTypeSelect = (values) => {
    handleChangePage({}, 0);
    setFilterType(values);
  };
  const onFilterLookerTypeSelect = (values) => {
    handleChangePage({}, 0);
    setFilterLookerType(values);
  };

  const onMonitors = (dataset, table) => {
    history.push({
      pathname: AppRoutes.Monitors.path,
      search: new URLSearchParams({ dataset, table }).toString(),
      state: { ...history.location.state },
    });

    amplEvent(
      `${EVENT_CLICK} ${currentTab} -> click table ${getFullTableName(
        table,
        dataset
      )} -> to Monitors page`
    );
  };

  const onDatasetClick = useCallback(
    (datasetName) => {
      handleChangePage({}, 0);
      history.push({
        search: new URLSearchParams({
          [SEARCH_PARAMS.TAB]: DictionaryTabs.TABLES,
          [SEARCH_PARAMS.DATASET]: datasetName,
        }).toString(),
        state: { ...history.location.state },
      });

      amplEvent(
        `${EVENT_CLICK} ${DictionaryTabs.DATASETS} -> click dataset ${datasetName} -> to ${DictionaryTabs.TABLES} tab`
      );
    },
    [history, handleChangePage]
  );

  const clearFiltersAndStates = () => {
    clearFilters();
    handleChangeBooleanState(false);
    clearLocationState();
  };

  const afterLabelAlertText = useMemo(() => {
    const isLookerTab = currentTab === DictionaryTabs.LOOKER;
    const isDatasetTab = currentTab === DictionaryTabs.DATASETS;
    const isDefaultAlert = filterAlert[0] === ALERT_FILTER_OPTIONS[0];
    const isDefaultLookerAlert =
      filterLookerAlert[0] === ALERT_FILTER_OPTIONS[0];

    if (isLookerTab) {
      return isDefaultLookerAlert ? ' of dashboards' : ' dashboards';
    }
    if (isDatasetTab) {
      return isDefaultAlert ? ' of datasets' : ' datasets';
    }

    return isDefaultAlert ? ' of tables' : ' tables';
  }, [currentTab, filterAlert, filterLookerAlert]);

  return (
    <>
      <PageHeader hasDateRangePicker={false}>Dictionary</PageHeader>

      <div className={classes.contentContainer}>
        <Tabs
          tabs={DictionaryTabs}
          onTabCLick={clearFiltersAndStates}
          paramsToRemove={PARAMS_TO_REMOVE}
        />

        <div className={classes.dropdownsContainer}>
          {existingDropdown.tableAlert && (
            <StyledDropdown
              options={ALERT_FILTER_OPTIONS}
              defaultValue={filterAlert}
              Icon={RegularMiniIcon}
              onSelect={onFilterAlertSelect}
              selectAllOption={ALERT_FILTER_OPTIONS[0]}
              afterLabel={afterLabelAlertText}
            />
          )}

          {existingDropdown.stream && (
            <StyledDropdown
              options={DICTIONARY_TYPES_STREAM}
              defaultValue={filterTypeStream}
              Icon={DictionaryStreamIcon}
              onSelect={onFilterStreamSelect}
              selectAllOption={DICTIONARY_TYPES_STREAM[0]}
              beforeLabel='Show '
              multipleSelect={false}
            />
          )}

          {existingDropdown.tableType && (
            <StyledDropdown
              options={DICTIONARY_TYPES_OPTIONS}
              defaultValue={filterType}
              Icon={DictionaryTypeIcon}
              onSelect={onFilterTypeSelect}
              selectAllOption={DICTIONARY_TYPES_OPTIONS[0]}
              afterLabel=' type'
              beforeLabel='Show '
            />
          )}

          {existingDropdown.lookerAlertType && (
            <StyledDropdown
              options={ALERT_FILTER_OPTIONS}
              defaultValue={filterLookerAlert}
              Icon={RegularMiniIcon}
              onSelect={onFilterLookerAlertSelect}
              selectAllOption={ALERT_FILTER_OPTIONS[0]}
              afterLabel={afterLabelAlertText}
            />
          )}

          {existingDropdown.reportsType && (
            <StyledDropdown
              options={DICTIONARY_LOOKER_TYPES_OPTIONS}
              defaultValue={filterLookerType}
              Icon={DictionaryTypeIcon}
              onSelect={onFilterLookerTypeSelect}
              selectAllOption={DICTIONARY_LOOKER_TYPES_OPTIONS[0]}
              afterLabel=' type'
              beforeLabel='Show '
              multipleSelect={false}
            />
          )}

          {existingDropdown.suggestedCritical && (
            <>
              <div className={classes.verticalDivider} />
              <SuggestedPriority
                isSelected={isSuggestedPriority}
                handleChangeSelected={handleChangeSelectedSuggested}
              />
            </>
          )}
        </div>

        {renderPage()}

        <Backdrop isOpen={isOpenBackdrop} isPageCentering={true} />
      </div>

      {!isOpenBackdrop && (
        <Pagination
          count={totalNumber}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </>
  );
};

export default Dictionary;
