import {
  useGetVolumeData,
  useGetVolumeRawData,
  useEffect,
  useMemo,
  useGetVolumeThreshold,
  useTableAnalysis,
  useTableFrequency,
} from 'hooks/hooks.js';
import { Volume, Scatter, PositionLoader } from 'Components/components.js';
import { getUnixDateByStr } from 'utils/helpers/helpers.js';
import { getAreaData, getAreaDataWithThresholdCoefficient } from './utils.js';
import { EXISTING_DATA_TYPES } from 'Pages/Monitors/libs/constants.js';

const TableDataVolume = ({
  table,
  dataset,
  onChangeExistingData,
  onChangeExistingButton,
  currentDatetime = null,
  withScatterGraph = false,
  customPercentThreshold = null,
  isGraphRefreshing = false,
  handleClearRefreshing = null,
}) => {
  const isEditingThresholdCoefficient = customPercentThreshold !== null;

  const { percentThreshold } = useTableAnalysis({
    dataset,
    table,
  });

  const {
    isFetching: isFetchingVolumeData,
    data,
    refetch: refetchVolumeData,
  } = useGetVolumeData({
    table,
    dataset,
    isEnabled: !isEditingThresholdCoefficient,
  });

  const { metricVolumeThreshold } = useGetVolumeThreshold({
    dataset,
    table,
    isEnabled: isEditingThresholdCoefficient,
  });

  const { isFetching: isFetchingScatterData, data: metrics } =
    useGetVolumeRawData({
      dataset,
      table,
      isEnabled: withScatterGraph,
    });

  const { frequencyMilliseconds, isFrequencyFetching, refetchFrequency } =
    useTableFrequency({ table, dataset });

  const volumeData = useMemo(() => {
    if (isEditingThresholdCoefficient && metricVolumeThreshold.length) {
      return getAreaDataWithThresholdCoefficient(
        metricVolumeThreshold,
        customPercentThreshold
      );
    }
    if (data?.values.length > 0) {
      return getAreaData(data?.values);
    }

    return [];
  }, [
    data?.values,
    isEditingThresholdCoefficient,
    metricVolumeThreshold,
    customPercentThreshold,
  ]);

  const scatterData = useMemo(() => {
    if (volumeData.length && metrics?.values) {
      return [
        { datetime: volumeData.at(0).datetime, value: null },
        ...metrics.values,
        {
          datetime:
            getUnixDateByStr(volumeData.at(-1).datetime) +
            frequencyMilliseconds,
          value: null,
        },
      ];
    }

    return [];
  }, [frequencyMilliseconds, metrics?.values, volumeData]);

  const existingScatterGraph = useMemo(() => {
    return metrics?.values.length > 0 && withScatterGraph;
  }, [metrics?.values.length, withScatterGraph]);

  const isLoadingData = useMemo(() => {
    return (
      isFetchingVolumeData ||
      isFrequencyFetching ||
      (withScatterGraph && isFetchingScatterData)
    );
  }, [
    isFetchingScatterData,
    isFetchingVolumeData,
    isFrequencyFetching,
    withScatterGraph,
  ]);

  useEffect(() => {
    if (onChangeExistingButton) {
      onChangeExistingButton(data?.values?.length > 0 || false);
    }

    if (!onChangeExistingData) return;

    const hasValues = data?.values?.length > 0 || metrics?.values?.length > 0;
    const isFetching =
      isFetchingVolumeData || isFrequencyFetching || isFetchingScatterData;

    if (hasValues || isFetching) {
      onChangeExistingData(EXISTING_DATA_TYPES.volume, true);
    } else {
      onChangeExistingData(EXISTING_DATA_TYPES.volume, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data?.values,
    metrics?.values,
    isFetchingVolumeData,
    isFrequencyFetching,
    isFetchingScatterData,
  ]);

  useEffect(() => {
    if (isGraphRefreshing) {
      refetchFrequency()
        .then(() => refetchVolumeData())
        .finally(() => handleClearRefreshing && handleClearRefreshing());
    }
  }, [isGraphRefreshing]);

  if (isLoadingData) {
    return <PositionLoader />;
  }

  if (!volumeData.length && metrics?.values.length) {
    return <Scatter scatterData={metrics.values} />;
  }

  return (
    <>
      {volumeData.length > 0 && (
        <Volume
          volumeData={volumeData}
          scatterData={scatterData}
          frequencyMilliseconds={frequencyMilliseconds}
          existingScatterGraph={existingScatterGraph}
          currentDatetime={currentDatetime}
          percentThreshold={percentThreshold}
          isEditingVolume={isEditingThresholdCoefficient}
        />
      )}
    </>
  );
};

export { TableDataVolume };
